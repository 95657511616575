export const CommonModel={
    listLoader:false,
    saveLoader: false,
}

export const SRMessageModel={
    key:'',
    messageBody: '',
    description:''
}

export const PaginationModel={
    numericField:0,
    numericField2:0,
    textField:'',
    textField2:'',
    startIndex:1,
    endIndex:10,
    boolField:null
}
export const SearchVM={
    searchCategory:0,
    searchText:'',
    zipCode:'',
    startIndex:1,
    endIndex:10
  }
