import {React, memo} from "react";
import { Routes, Route, Link } from "react-router-dom";
function Profile(){
return (
    <div id="content" className="app-content">
			<div className="profile-header">
				<div className="profile-header-cover"></div>
				<div className="profile-header-content">
					<div className="profile-header-img">
						<img src="assets/img/user.jpg" alt="" />
					</div>
					<div className="profile-header-info">
						<h4>Clyde Stanley</h4>
						<p>UXUI + Frontend Developer</p>
						<Link to="/EditProfile"  className="btn btn-xs btn-rounded btn-primary width-100">Edit Profile</Link>
					</div>
				</div>
				<ul className="profile-header-tab nav nav-tabs">
					<li className="nav-item"><a href="#profile-about" className="nav-link" data-toggle="tab">Profile</a></li>
				</ul>
			</div>
			<div className="profile-container">
				<div className="row row-space-20">
					<div className="col-xl-12">
						<div className="tab-content p-0">
							<div className="tab-pane fade show active" id="profile-about">
								<table className="table table-profile">
									<thead>
										<tr>
											<th colspan="2">WORK AND EDUCATION</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="field">Work</td>
											<td className="value">
												<div className="m-b-5">
													<b>Magnificient IT (2017)</b> <a href="#" className="m-l-10">Edit</a><br />
													<span className="text-muted">PHP Programmer</span>
												</div>
												<div>
													<b>Neutrino IT (2012)</b> <a href="#" className="m-l-10">Edit</a><br />
													<span className="text-muted">UXUI / Frontend Developer</span>
												</div>
											</td>
										</tr>
										<tr>
											<td className="field">Education</td>
											<td className="value">
												<div className="m-b-5">
													<b>University (2009)</b> <a href="#" className="m-l-10">Edit</a><br />
													<span className="text-muted">University of Georgia, Athens, GA</span>
												</div>
												<div>
													<b>High School (2006)</b> <a href="#" className="m-l-10">Edit</a><br />
													<span className="text-muted">Heritage High School, West Chestter, PA</span>
												</div>
											</td>
										</tr>
										<tr>
											<td className="field">Skills</td>
											<td className="value">
												C++, PHP, HTML5, CSS, jQuery, MYSQL, Ionic, Laravel, Phonegap, Bootstrap, Angular JS, Angular JS, Asp.net
											</td>
										</tr>
									</tbody>
								</table>
								<table className="table table-profile">
									<thead>
										<tr>
											<th colspan="2">CONTACT INFORMATION</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="field">Mobile Phones</td>
											<td className="value">
												+44 7700 900860
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
										<tr>
											<td className="field">Email</td>
											<td className="value">
												admin@admetro.com
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
										<tr>
											<td className="field">Facebook</td>
											<td className="value">
												http://facebook.com/admetro
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
										<tr>
											<td className="field">Website</td>
											<td className="value">
												http://seantheme.com
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
										<tr>
											<td className="field">Address</td>
											<td className="value">
												Twitter, Inc. <a href="#" className="m-l-10">Edit</a><br />
												1355 Market Street, Suite 900<br />
												San Francisco, CA 94103
											</td>
										</tr>
									</tbody>
								</table>
								<table className="table table-profile">
									<thead>
										<tr>
											<th colspan="2">BASIC INFORMATION</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="field">Birth of Date</td>
											<td className="value">
												November 4, 1989
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
										<tr>
											<td className="field">Gender</td>
											<td className="value">
												Male
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
										<tr>
											<td className="field">Facebook</td>
											<td className="value">
												http://facebook.com/admetro
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
										<tr>
											<td className="field">Website</td>
											<td className="value">
												http://seantheme.com
												<a href="#" className="m-l-10">Edit</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>							
						
						</div>
					</div>
					
				</div>
				{/* <!-- END row --> */}
			</div>
			{/* <!-- END profile-container --> */}
</div>

)

}

export default memo(Profile);