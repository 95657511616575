import React, { useState, useRef, useEffect } from "react";
import { IoTSettingModel, IoTUserDevice } from "../Models/IoT";
import {
  GetDevices,
  GetIoTSetting,
  UpdateIoTControl,
} from "../Services/Service";
import { getUserKey } from "../common";
import GlobalSetting from "../config";

const IoT = () => {
  const iframeRef = useRef(null);
  const [userDevice, setUserDevice] = useState(IoTUserDevice);
  const [controls, setControls] = useState([]);
  const [iotSetting, setIoTSetting] = useState(IoTSettingModel);

  useEffect(() => {
    getDevices();
  }, [userDevice.userDeviceId]);

  const getDevices = async () => {
    var response = await GetDevices("view");
    if (
      response != null &&
      response.status == 200 &&
      response.data != null &&
      response.data.length > 0
    ) {
      setUserDevice({ ...userDevice, userControls: response.data });
      getIoTSetting(response.data[0].deviceId);
    }
  };

  const getIoTSetting = async (deviceId) => {
    var response = await GetIoTSetting(deviceId);
    if (response != null && response.status == 200 && response.data != null) {
      setIoTSetting(response.data);     
      let webServerUrl=response.data.url+"?url="+response.data.url+"&device="+response.data.deviceId+"&code="+ getUserKey('userIdentifier');
      var iframeElement = document.getElementById("iframe_iot");
      iframeElement.src = webServerUrl;
      // if (response.data.iotControls != null && response.data.iotControls != "")
      //   setControls(JSON.parse(response.data.iotControls));
    }
  };

  const userDeviceEdit = (e) => {
    const { name, value } = e.target;
    setUserDevice((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    getIoTSetting(value);
  };

  const sendMessageToIframe = (message) => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.contentWindow.postMessage(message, "*");
    }
  };

  const handleControlChange = (index, e, control) => {
    let testVal = null;
    if (control.type == "toggleButton") {
      let arr = control.value.split(",");
      if (e.target.checked == true) testVal = arr[1];
      else testVal = arr[0];

      const updatedArray = [...controls];
      updatedArray[index] = {
        ...updatedArray[index],
        ["state"]: testVal,
      };
      setControls(updatedArray);
      handleUpdateControl(updatedArray);
    } else if (control.type == "messageButton") {
      testVal = control.value;
    }

    let messageObj = {
      requestType: control.eventType,
      messageText:
        control.type == "button" ? control.value : control.name + ":" + testVal,
    };
    let message = JSON.stringify(messageObj);
    sendMessageToIframe(message);
  };

  const handleUpdateControl = async (controlDetails) => {
    let iotPayload = {
      id: iotSetting.id,
      controls: JSON.stringify(controlDetails),
    };
    var response = await UpdateIoTControl(iotPayload);
  };

  const handleIframeMessage = (event) => {
    // Check the origin of the message to verify it's from a trusted source
    // if (event.origin === 'https://www.example.com') {
    alert("Message received from iframe:" + event.data);
    // }
  };

  return (
    <div class="container mt-5 vstack gap-4">
      <div class="row" style={{ background: "#c8e3fa" }}>
        <div class="col-12 mb-4 mb-sm-1">
          <div
            class="d-sm-flex justify-content-between align-items-center"
            style={{ marginTop: "1% !important" }}
          >
            <h4 class="h4 mb-2 mb-sm-0">IoT</h4>
          </div>
        </div>
      </div>
      <div class="row" style={{ background: "#f7f7f7" }}>
        <div class="col-12 mt-4 mb-4 mb-sm-5">
          <div class="row mb-3">
            <div class="col-3">
              <label class="form-label">Select DeviceId</label>
              <select
                className="form-select js-choice form-control form-control-lg me-1"
                data-search-enabled="true"
                name="userDeviceId"
                value={userDevice.userDeviceId}
                onChange={userDeviceEdit}
              >
                {userDevice.userControls.map((k) => {
                  return <option value={k.deviceId}>{k.deviceName}</option>;
                })}
              </select>
            </div>
          </div>
          <hr></hr>
          <div class="row">
            <div class="col-12 mb-4 text-center">
              {" "}
              <h5>Your Webserver</h5>
            </div>
            <iframe
              id="iframe_iot"
              title="Embedded Iframe"
              ref={iframeRef}
              width="960"
              height="569"
              frameBorder="0"
              allowfullscreen="true"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IoT;
