// import logo from './logo.svg';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import {React} from 'react';
import MainView from './Admin/MainView';
import { useSelector } from 'react-redux';
function App() {
  var userDetail =useSelector((store)=>store.userDetail); 
    let currentRoute="root";
    const location = useLocation();    

    if(location.pathname.indexOf('Admin')> -1 && userDetail.firstName=='')
    {
      currentRoute="NotLoggedIn";
      window.location='/'; 
    }

    return (
        <>
        {/* {currentRoute !='NotLoggedIn' && <MainView />} */}
            {/* <Provider store={store}> */}
        {/* {currentRoute == "Login" ? <Login /> : <MainView />}  */}
        {/* </Provider> */}
      {/* {currentRoute == "Register" && <Register />}  */}
               

        </>
    );
}

export default App;