import {createSlice} from '@reduxjs/toolkit';

const commonSlice=createSlice({
    name:'commonState',
    initialState:{
      isLoaderVisible:false
    },
    reducers:{
        toggleLoaderVisible:(state,{payload})=>{
            state.isLoaderVisible=payload;
        },
    }
})
console.log(commonSlice);
export const {toggleLoaderVisible}=commonSlice.actions;
export default commonSlice.reducer;