import { React, useState, useEffect } from "react";
import { useParams } from "react-router";
import { GetUserLead,SaveLeadDetail } from "../Services/Service";
import { LeadDTO } from "../Models/LeadDTO";
import { PaginationModel } from "../Models/commonModel";
import LeadDetailModal from "../Modals/leadDetailModal";
import { showSaveLoader,showListLoader,convertDatetime,getMessageModel } from "../common";
import { toast } from 'react-toastify';
import SignalRService from "../Services/SignalRService";
import {CommonModel} from '../Models/commonModel';
function LeadDetail() {
  const { id } = useParams();
  const [leadList, setLeadList] = useState([]);
  const [pages, setPages] = useState(PaginationModel);
  const [leadDetail, setLeadDetail] = useState(LeadDTO);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [dateValue, onDateChange] = useState(new Date());
  const [showLoader, setShowLoader] = useState(CommonModel);
  useEffect(() => {
    setPagination();
    getLeadDetailByStatus();
  }, []);

  const setPagination = () => {
    setPages((prevUserData) => ({
      ...prevUserData,
      ["numericField2"]: id,
    }));
  };

  const getLeadDetailByStatus = async () => {
    //showListLoader(setShowLoader,true);
    var response = await GetUserLead(pages);
    if (response != null && response.status == 200 && response.data != null) {
      //  showListLoader(setShowLoader,false);
      setLeadList(response.data);
    }
    //   else
    //   showListLoader(setShowLoader,false);
  };

  const leadDetailEdit = (e) => {
    const { name, value } = e.target;
    setLeadDetail((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const openLeadDetailModal = (
    leadId,
    customerName,
    contactNumber,
    emailAddress,
    serviceName,
    description,
    serviceRequestTime,
    amountPaid,
    duration
  ) => {
    setLeadDetail((prevUserData) => ({
      ...prevUserData,
      ["id"]: leadId,
      ["serviceName"]: serviceName,
      ["name"]: customerName,
      ["description"]: description,
      ["contactNumber"]: contactNumber,
      ["emailAddress"]: emailAddress,
      ["serviceRequestTime"]: serviceRequestTime,
      ["amountPaid"]: amountPaid,
      ["duration"]: duration,
    }));
    if(serviceRequestTime !=null && serviceRequestTime!='')
       onDateChange(serviceRequestTime);
    else
       {
        var currentdate = new Date(); 
        onDateChange(currentdate);
       }
    setShowModal(true);
  };
  const leadDetailSubmit = async (leadStatus) => {
    showSaveLoader(setShowLoader, true);
    leadDetail.serviceRequestTime = dateValue;
    if(leadStatus=='accepted')
      leadDetail.statusId='7';
    else if(leadStatus=='rejected')
      leadDetail.statusId='8';
    var response = await SaveLeadDetail(leadDetail);
    if (response != null && response.status == 200 && response.data != null) {
      SignalRService.sendMessage(
        "",
        getMessageModel("LeadMessageByBusiness", response.data.response_text, "")
      );
      showSaveLoader(setShowLoader, false);
      setShowModal(false);
      toast.success(response.data.response_text, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      showSaveLoader(setShowLoader, false);
      setShowModal(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
     <div class="container mt-5 vstack gap-4">
      <LeadDetailModal
        leadDetailSubmit={leadDetailSubmit}
        leadDetail={leadDetail}
        leadDetailEdit={leadDetailEdit}
        onDateChange={onDateChange}
        dateValue={dateValue}
        showModal={showModal}
        handleClose={handleClose}
      />
      <div class="row" style={{background: '#c8e3fa'}}>
        <div class="col-12 mb-4 mb-sm-1">
          <div class="d-sm-flex justify-content-between align-items-center">
            <h5 class="h4 mb-2 mb-sm-0">Lead List</h5>
          </div>
        </div>
      </div>
      {/* <h5 className="page-header">
       Booking Ad Setting <small>add/manage your ad setting</small>
      </h5> */}

      <div class="row" style={{background: '#f7f7f7'}}>
        <div class="col-12 mt-4 mb-4 mb-sm-5">
          <div class="row m-b-15">
            <div class="col-sm-6">
              <div class="dataTables_length" id="datatables-default_length">
                <label>
                  Show
                  <select
                    name="datatables-default_length"
                    aria-controls="datatables-default"
                    class="custom-select custom-select-sm form-control form-control-sm"
                  >
                    <option value="20">20</option>
                    <option value="40">40</option>
                  </select>{" "}
                  entries
                </label>
              </div>
            </div>
            <div class="col-sm-6 text-right">
              <div class="d-flex justify-content-end">
                <div id="datatables-default_filter" class="dataTables_filter">
                  <label>
                    Search:
                    <input
                      type="search"
                      class="form-control form-control-sm"
                      placeholder=""
                      aria-controls="datatables-default"
                    ></input>
                  </label>
                </div>
                <div class="dt-buttons btn-group">
                  <button
                    class="btn btn-secondary buttons-copy buttons-html5 btn-default btn-sm"
                    tabIndex="0"
                    aria-controls="datatables-default"
                    type="button"
                  >
                    <span>Copy</span>
                  </button>
                  <button
                    class="btn btn-secondary buttons-print btn-default btn-sm"
                    tabIndex="0"
                    aria-controls="datatables-default"
                    type="button"
                  >
                    <span>Print</span>
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
          <table
            id="datatables-default"
            class="table table-striped table-td-valign-middle table-bordered bg-white"
          >
            <thead>
              <tr>
                <th width="1%" class="no-sort">
                  #
                </th>
                <th width="10%">Cutomer Name</th>
                <th width="10%">Mobile No.</th>
                <th width="10%">Email</th>
                <th width="10%">Service Request</th>
                <th width="20%">Requested Time</th>
                <th width="10%">Amount Paid</th>
                <th width="1%" class="no-sort">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {leadList.map((k, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{k.name}</td>
                    <td>{k.contactNumber} </td>
                    <td>{k.emailAddress}</td>
                    <td>{k.serviceName}</td>
                    <td>
                      {k.serviceRequestTime != null &&
                        convertDatetime(k.serviceRequestTime)}
                    </td>
                    <td>{k.amountPaid}</td>
                    <td class="btn-col text-nowrap" width="1%">
                      <button
                        class="btn btn-info m-b-5 m-r-3"
                        onClick={() =>
                          openLeadDetailModal(
                            k.id,
                            k.name,
                            k.contactNumber,
                            k.emailAddress,
                            k.serviceName,
                            k.description,
                            k.serviceRequestTime,
                            k.amountPaid,
                            k.duration
                          )
                        }
                      >
                        View
                      </button>
                      <a href="#" class="btn btn-silver btn-xs m-r-2">
                        <i class="fa fa-cog"></i>
                      </a>
                      <a href="#" class="btn btn-silver btn-xs">
                        <i class="fa fa-times"></i>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </>
  );
}
export default LeadDetail;
