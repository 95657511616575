import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {LoggedDetail} from '../Models/LoggedDetail';
import { GetUserDetailbyId} from '../Services/Service';
export const getDetail = createAsyncThunk('user/Detail',async ()=>{
return await GetUserDetailbyId();
});

const userSlice=createSlice({
    name:'userDetail',
    initialState:LoggedDetail,
    reducers:{
        setUserDetail:(state,{payload})=>{ 
            if(payload!=null)
            {
            state.firstName=payload.firstName;
            state.lastName=payload.lastName;
            state.profileImage=payload.profileImage;
            state.notificationCount=payload.notificationCount;
           // Object.assign(state,payload);
            }
            else
            {
            state.firstName='';
            state.lastName='';
            state.profileImage='';
            state.notificationCount=0;
            }
        },
        getUserDetail:(state)=>{
           return state;
        }
    },
    extraReducers:{
[getDetail.pending]:(state)=>{

},
[getDetail.fulfilled]:(state,action)=>{
state.LoggedDetail=action.payload;
},
[getDetail.rejected]:(state)=>{
   
    }
    }
})
console.log(userSlice);
export const {setUserDetail,getUserDetail}=userSlice.actions;

export default userSlice.reducer;