// import './App.css';
import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { toast } from "react-toastify";
//import {setUserDetail} from '../Slices/userSlice';
import {
  showSaveLoader,
  showListLoader,
  getUserKey,
  checkUserIdentifier,
  getBusinessTypes,
  getMessageModel,
  getImageUrl,
} from "../common";
import { BusinessProfileModel } from "../Models/Basic-Detail";
import { LeadDTO } from "../Models/LeadDTO";
import {
  GetBusinessDetailList,
  GetServices,
  SaveLeadDetail,
} from "../Services/Service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CommonModel, SRMessageModel, SearchVM } from "../Models/commonModel";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ServiceDetailModal from "../Modals/serviceDetailModal";
import SignalRService from "../Services/SignalRService";
function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [businessProfile, setBusinessProfile] = useState(BusinessProfileModel);
  const [leadDetail, setLeadDetail] = useState(LeadDTO);
  const [businessList, setBusinessList] = useState([]);
  const [showLoader, setShowLoader] = useState(CommonModel);
  var currentdate = new Date();
  const [dateValue, onDateChange] = useState(currentdate);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [searchModel, setSearchModel] = useState(SearchVM);
  useEffect(() => {
    //checkUserIdentifier(navigate,dispatch);
    //getBusinessTypes(setBusinessProfile,false);
    getBusinessDetail();
  }, []);

  const getBusinessDetail = async () => {
    var response = await GetBusinessDetailList();
    if (response != null && response.status == 200 && response.data != null) {
      setBusinessList(response.data.businessDetail);
      setBusinessProfile((prevData) => ({
        ...prevData,
        ["businessTypeList"]: response.data.businessType,
      }));
    }
  };

  const getServiceList = async (businessId) => {
    var response = await GetServices(businessId);
    if (response != null && response.status == 200 && response.data != null) {
      setLeadDetail((prevUserData) => ({
        ...prevUserData,
        ["services"]: response.data,
      }));
    }
  };

  const onClickItem = (e) => {
    debugger;
  };
  const businessProfileEdit = (e) => {
    const { name, value } = e.target;
    setBusinessProfile((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const searchModelEdit = (e) => {
    const { name, value } = e.target;
    setSearchModel((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const openBookingModal = (businessId, registeredName) => {
    getServiceList(businessId);
    setLeadDetail((prevUserData) => ({
      ...prevUserData,
      ["targetBusinessName"]: registeredName,
      ["targetBusiness"]: businessId,
    }));
    setShowModal(true);
  };
  const leadDetailEdit = (e) => {
    const { name, value } = e.target;
    setLeadDetail((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const leadDetailSubmit = async () => {
    showSaveLoader(setShowLoader, true);
    leadDetail.serviceRequestTime = dateValue;
    var response = await SaveLeadDetail(leadDetail);
    if (response != null && response.status == 200 && response.data != null) {
      SignalRService.sendMessage(
        "",
        getMessageModel(
          "LeadMessageByCustomer",
          response.data.response_text,
          ""
        )
      );
      showSaveLoader(setShowLoader, false);
      setShowModal(false);
      toast.success(response.data.response_text, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      showSaveLoader(setShowLoader, false);
      setShowModal(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <ServiceDetailModal
        leadDetail={leadDetail}
        showModal={showModal}
        handleClose={handleClose}
        leadDetailSubmit={leadDetailSubmit}
        leadDetailEdit={leadDetailEdit}
        onDateChange={onDateChange}
        dateValue={dateValue}
      />

      {/* <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
		<h5 class="modal-title" id="inquiryFormlabel">Service Booking</h5>
        </Modal.Header>
        <Modal.Body><div class="card bg-transparent">
					<div class="card-header bg-transparent">
				<h6 class="card-title mb-0"> {leadDetail.targetBusinessName}</h6>
					</div>

					<div class="card-body pt-0">
						<div>			
						<div class="mb-3">
								<label class="form-label">Select Service</label>
								<select class="form-control" name="serviceType" value={leadDetail.serviceType} onChange={leadDetailEdit}>
									<option key="-1">--Select Service--</option>
									{ leadDetail.services!=null &&	leadDetail.services.map((k)=>{
										return <option value={k.id}>{k.name}</option>
									})
								}
								</select>
							</div>			
							<div class="mb-3">
								<label class="form-label">Name</label>
								<input type="text" class="form-control" placeholder="Enter Your name"
								name="name" value={leadDetail.name} onChange={leadDetailEdit}></input>
							</div>
						
							<div class="mb-3">
								<label class="form-label">Phone number</label>
								<input type="text" class="form-control" placeholder="Enter Your phone number"
								name="contactNumber" value={leadDetail.contactNumber} onChange={leadDetailEdit}></input>
							</div>
							<div class="mb-3">
								<label class="form-label">Remark</label>
								<textarea type="text" class="form-control" placeholder="Enter Your Remark"
								name="description" value={leadDetail.description} onChange={leadDetailEdit}></textarea>
							</div>

							<div class="mb-3 ms-2">
						      	<label class="form-label">Enter Booking Datetime</label>
								<br></br>
							    <DateTimePicker onChange={onChange} calendarIcon={null} showLeadingZeros={true} disableClock={true} format="dd/MM/y h:mm a" value={dateValue} />
							</div>
							<div class="d-grid gap-2 d-md-block">
								<button onClick={()=>leadDetailSubmit()} class="btn btn-dark mb-0 form-control" type="button">Creating Booking</button>							
							</div>
						</div> 
					</div>
				</div></Modal.Body>
      </Modal> */}

      {/* 
				<div class="modal fade" id="roomDetail" tabindex="-1" aria-labelledby="roomDetailLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content p-0">
			<div class="modal-header p-3">
				<h5 class="modal-title mb-0" id="roomDetailLabel">Room detail</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body p-0">
				<div class="card bg-transparent p-3">
					<div class="tiny-slider arrow-round arrow-dark overflow-hidden rounded-2">
						<div class="tiny-slider-inner rounded-2 overflow-hidden" data-autoplay="true" data-arrow="true" data-dots="false" data-items="1">
							<div> <img src="assets/images/gallery/16.jpg" class="rounded-2" alt="Card image"></div>
						</div>
					</div>

					<div class="card-header bg-transparent pb-0">
						<h3 class="card-title mb-0">Deluxe Pool View</h3>
					</div>
					<div class="card-body">
						<p>Club rooms are well furnished with air conditioner, 32 inch LCD television and a mini bar. They have attached bathroom with showerhead and hair dryer and 24 hours supply of hot and cold running water. Complimentary wireless internet access is available. Additional amenities include bottled water, a safe and a desk.</p>
						
						<div class="row">
							<h5 class="mb-0">Amenities</h5>
							<div class="col-md-6">
								<ul class="list-group list-group-borderless mt-2 mb-0">
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">Swimming pool</span>
									</li>
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">Spa</span>
									</li>
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">Kids play area.</span>
									</li>
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">Gym</span>
									</li>
								</ul>
							</div>
							<div class="col-md-6">
								<ul class="list-group list-group-borderless mt-2 mb-0">
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">TV</span>
									</li>
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">Mirror</span>
									</li>
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">AC</span>
									</li>
									<li class="list-group-item d-flex mb-0">
										<i class="fa-solid fa-check-circle text-success me-2"></i><span class="h6 fw-light mb-0">Slippers</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> */}

      <section
        className="position-relative py-8 py-lg-9"
        style={{
          backgroundImage: "url(assets/images/bg/20.jpg)",
          backgroundPosition: "center left",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-overlay bg-dark opacity-8"></div>
        <div className="container z-index-9 position-relative">
          <div className="row py-sm-5">
            <div className="col-xl-8 m-auto text-center">
              <h6 className="text-white fw-normal mb-3">
                A platform with multiple services
              </h6>
              <h1 className="display-4 text-white mb-0">
                Digital App
                <span className="position-relative z-index-9 d-block">
                  Zone
                  {/* <!-- SVG START --> */}
                  <span className="position-absolute top-50 start-50 translate-middle z-index-n1 d-none d-md-block mt-4">
                    {/* <svg width="390.5px" height="21.5px" viewBox="0 0 445.5 21.5">
									<path className="fill-primary opacity-7" d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z"/>
								</svg> */}
                  </span>
                  {/* <!-- SVG END --> */}
                </span>
              </h1>
            </div>

            {/* <!-- Search START --> */}
            <div className="col-xl-10 mx-auto">
              <div className="bg-blur bg-white bg-opacity-10 border border-light border-opacity-25 rounded-3 p-4 mt-5">
                {/* <!-- Form START --> */}
                <form className="row g-3 justify-content-center align-items-center">
                  <div class="col-lg-5">
                      <i class="bi bi-geo-alt fs-4 me-2" style={{display:'none',color: 'aliceblue', marginTop: '15px'}}></i>
                      <div style={{float: 'right', width: '90%'}}>
                          <select
                            className="form-select js-choice form-control form-control-lg me-1"
                            data-search-enabled="true"
                            name="searchCategory"
                            value={searchModel.searchCategory}
                            onChange={searchModelEdit}
                          >
                            <option value="-1">--All Service--</option>
                            {businessProfile.businessTypeList.map((k) => {
                              return <option value={k.id}>{k.name}</option>;
                            })}
                          </select>
                        </div>
                    </div>

                  <div className="col-lg-5">
                    <div className="form-input position-relative">
                      <input
                        name="searchText"
                        value={searchModel.searchText}
                        onChange={searchModelEdit}
                        className="form-control form-control-lg me-1 ps-5"
                        type="text"
                        placeholder="What are you looking for.."
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-2 d-grid">
                    <Link
                      className="btn btn-lg btn-primary mb-0"
                      to={
                        "/Search/" +
                        searchModel.searchCategory +
                        "/" +
                        (searchModel.searchText != ""
                          ? searchModel.searchText
                          : "nothing")
                      }
                    >
                      Search
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-2">
              <h4>Browse Service</h4>
              <a href="#" className="h6 fw-normal text-primary mb-0">
                View all <i className="bi bi-arrow-right"></i>
              </a>
            </div>
            <div className="col-lg-10">
              <div className="tiny-slider arrow-round arrow-xs arrow-dark">
                <div className="tiny-slider-inner">
                  <Carousel
                    showThumbs={false}
                    showArrows={true}
                    autoPlay={true}
                    centerMode={true}
                    centerSlidePercentage={20}
                    showStatus={false}
                    showIndicators={false}
                  >
                    {businessProfile.businessTypeList.map((k) => {
                      return (
                        <div className="businessTypeSlider">
                          {" "}
                          <Link to={"/Search/" + k.id + "/nothing"}>
                            <div className="card card-body bg-light text-center align-items-center">
                              <div className="icon-xl bg-mode rounded-circle mb-3">
                                <figure>
                                  <svg
                                    width="47.9px"
                                    height="47.3px"
                                    viewBox="0 0 47.9 47.3"
                                    style={{
                                      enableBackground: "new 0 0 47.9 47.3",
                                    }}
                                  >
                                    <path
                                      className="fill-mode-inverse"
                                      d="M37.5,20.3c-2.3,1.5-3.6,4.1-3.3,6.9l-10,6.4l-10-6.4c0.3-2.7-1-5.4-3.3-6.9c-1.5-1-3.3-1.5-5.2-1.3 c-3.2,0.3-5.6,3.2-5.3,6.4c0,0.4,0.1,0.7,0.2,1.1c0.5,1.8,1.6,3.3,3.1,4.3c2.2,1.6,5.2,1.8,7.6,0.5l7.8,5.5l-7.4,4.7 c-1.4,0.9-1.9,2.8-1,4.3c0,0,0,0.1,0.1,0.1c1,1.4,2.9,1.8,4.3,0.8c0,0,0,0,0.1,0l8.9-6.3l8.9,6.3c1.4,1,3.3,0.7,4.3-0.7 c1-1.4,0.7-3.3-0.7-4.3c-0.1,0-0.1-0.1-0.2-0.1l-7.4-4.7l7.8-5.5c2.4,1.3,5.4,1.1,7.6-0.5c3.3-2.2,4.3-6.3,2.4-9.2 S40.8,18.1,37.5,20.3L37.5,20.3z M14.4,45.4c-0.7,0.5-1.7,0.3-2.1-0.4c-0.5-0.7-0.3-1.6,0.4-2.1c0,0,0,0,0,0l7.9-5.1l2.2,1.6 L14.4,45.4z M36.4,43.9c0.2,0.8-0.4,1.6-1.2,1.8c-0.4,0.1-0.8,0-1.2-0.2L11.9,29.7c-0.3-0.2-0.6-0.2-0.9,0c-1.9,1.3-4.4,1.2-6.3-0.2 c-1.2-0.8-2.1-2-2.5-3.4c-0.7-2.3,0.6-4.6,2.8-5.3c0.3-0.1,0.6-0.1,0.9-0.2c1.4-0.2,2.9,0.2,4.1,1c2,1.2,3,3.5,2.5,5.7 c-0.1,0.3,0.1,0.7,0.3,0.9l22.9,14.7C36.1,43.1,36.4,43.5,36.4,43.9z M43.7,29.5c-1.8,1.4-4.3,1.4-6.3,0.2c-0.3-0.2-0.6-0.2-0.9,0 l-8.7,6.2l-2.1-1.3l9.9-6.3c0.3-0.2,0.4-0.5,0.3-0.9c-0.4-2.2,0.6-4.5,2.5-5.7c2.5-1.7,5.8-1.3,7.2,0.8 C47.1,24.6,46.2,27.8,43.7,29.5L43.7,29.5z"
                                    />
                                    <path
                                      className="fill-primary"
                                      d="M32.9,27l2.3-11.3c3.9-0.3,6.8-3.8,6.5-7.7c-0.3-3.7-3.4-6.5-7.1-6.5c-1.6,0-3.2,0.5-4.4,1.5 c-2.2-3.2-6.6-4.1-9.9-1.9c-0.7,0.5-1.4,1.1-1.9,1.9c-1.3-1-2.8-1.5-4.4-1.5c-3.9,0-7.1,3.2-7.1,7.1c0,3.7,2.8,6.8,6.5,7.1L15.5,27 l1.6-0.3l-0.4-2.2h15.2l-0.4,2.2L32.9,27z M29.1,22.9l0.7-4.6l-1.6-0.2l-0.7,4.9H25v-8.7h-1.6v8.7h-2.5l-0.7-4.9l-1.6,0.2l0.7,4.6 h-3l-1.6-8.1c-0.1-0.4-0.4-0.6-0.8-0.6c-3.1,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5c1.5,0,3,0.6,4,1.7c0.3,0.3,0.8,0.3,1.1,0 c0.1-0.1,0.1-0.1,0.2-0.2c1.3-2.7,4.6-3.9,7.4-2.6c1.1,0.5,2,1.4,2.6,2.6c0.2,0.4,0.7,0.6,1.1,0.4c0.1,0,0.2-0.1,0.2-0.2 c1-1.1,2.5-1.7,4-1.7c3.1,0,5.5,2.5,5.5,5.5s-2.5,5.5-5.5,5.5c-0.4,0-0.7,0.3-0.8,0.6l-1.6,8.1H29.1z"
                                    />
                                  </svg>
                                </figure>
                              </div>
                              <h6 className="mb-0">
                                <span>{k.name}</span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0 pt-sm-5">
        <div className="container">
          <div className="row mb-4 mb-sm-5">
            <div className="col-12 text-center">
              <h2 className="mb-0">Great places to Explore</h2>
              <p className="mb-0">
                Book your hotel with us and don't forget to grab an awesome
                hotel deal to save massive on your stay.
              </p>
            </div>
          </div>

          <div className="row g-4">
            {businessList.map((k) => {
              return (
                <div class="col-md-6 col-xl-4">
                  <div class="card shadow p-2 pb-0 h-100">
                    <div class="position-absolute top-0 start-0 z-index-1 m-4">
                      <div class="badge bg-danger text-white">30% Off</div>
                    </div>
                    <img
                      src={getImageUrl(k.businessCoverImage)}
                      className="card-img-top"
                      alt="Card image"
                    ></img>
                    <div class="card-body px-3 pb-0">
                      <div class="d-flex justify-content-between mb-3">
                        <a href="#" class="badge bg-dark text-white">
                          <i class="bi fa-fw bi-star-fill me-2 text-warning"></i>
                          4.5
                        </a>
                        <a href="#" class="h6 mb-0 z-index-2">
                          <i class="bi fa-fw bi-bookmark"></i>
                        </a>
                      </div>
                      <h5 class="card-title">
                        <a href="hotel-detail.html">{k.registeredName}</a>
                      </h5>
                      <h5>{k.businessDescription}</h5>
                      <ul class="nav nav-divider mb-2 mb-sm-3">
                        <li class="nav-item">Air Conditioning</li>
                        <li class="nav-item">Wifi</li>
                        <li class="nav-item">Kitchen</li>
                        <li class="nav-item">Pool</li>
                      </ul>
                    </div>

                    <div class="card-footer pt-0">
                      <div class="d-sm-flex justify-content-sm-between align-items-center">
                        <div class="d-flex align-items-center">
                          <h5 class="fw-normal text-success mb-0 me-1">$750</h5>
                          <span class="mb-0 me-2">/day</span>
                          <span class="text-decoration-line-through">
                            $1000
                          </span>
                        </div>
                        <div class="mt-2 mt-sm-0">
                          <button
                            class="btn btn-primary"
                            onClick={() =>
                              openBookingModal(k.id, k.registeredName)
                            }
                          >
                            Book Now
                          </button>
                          <Link
                            className="btn btn-sm btn-primary-soft mb-0 w-100"
                            to={"/Detail/" + k.id}
                          >
                            View detail
                            <i className="bi bi-arrow-right ms-1"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
