import Modal from "react-bootstrap/Modal";
import { React } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { convertDatetime } from "../common";
function LeadDetailModal(props) {
  return (
    <>
      <Modal show={props.showModal} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <h5 class="modal-title">Lead Summary</h5>
        </Modal.Header>
        <Modal.Body>
          <div class="card">
            {/* <div class="card-header">
					</div> */}
            <div class="card shadow">
              <div class="card-body p-4">
                {/* <h6 class="mb-0">Customer Detail</h6> */}
                <div class="row">
                  <div class="col-sm-12">
                    <ul class="list-group list-group-borderless mb-0">
                      <li class="list-group-item">
                        Customer Name:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.name}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Customer Email:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.emailAddress}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Customer Number:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.contactNumber}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Service Required:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.serviceName}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Booking Time:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.targetBusinessName}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Service Time:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.serviceRequestTime != null &&
                            convertDatetime(
                              props.leadDetail.serviceRequestTime
                            )}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Duration:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.duration}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Amount Paid:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.amountPaid}
                        </span>
                      </li>
                      <li class="list-group-item">
                        Remark:
                        <span class="h6 mb-0 fw-normal ms-1">
                          {props.leadDetail.description}
                        </span>
                      </li>
					  <li class="list-group-item">
						      	<b>Update Service Datetime:</b>
								<br></br>
							    <DateTimePicker is24Hour onChange={props.onDateChange} calendarIcon={null} showLeadingZeros={true} disableClock={true} format="dd/MM/y h:mm a" value={props.dateValue} />
					</li>
                      <li class="list-group-item">
                      <b> Your Remark:</b>
                        <span class="h6 mb-0 fw-normal ms-1">
                          <textarea
                            type="text"
                            class="form-control"
                            name="lastComment"
                            value={props.leadDetail.lastComment}
                            onChange={props.leadDetailEdit}
                          ></textarea>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div className="col-xs-2">
                    <button class="btn btn-info m-b-5 m-r-3"  onClick={()=>props.leadDetailSubmit('accepted')}>Accept</button>
                    &nbsp;
                    <button class="btn btn-danger m-b-5 m-r-3" onClick={()=>props.leadDetailSubmit('rejected')}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default LeadDetailModal;
