import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getDetail, setUserDetail } from "../Slices/userSlice";
import { setUserKey, getUserKey, checkUserIdentifier } from "../common";
import LoginModel from "../Models/Login-Model";
import {
  GetValidationMessage,
  LoginValidateColumn,
} from "../Models/validationFields";
import { checkLogin, GetUserDetailbyId } from "../Services/Service";
import { useDispatch } from "react-redux";
// import {Helmet} from "react-helmet";
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginModel, setloginModel] = useState(LoginModel);
  const loginValidation = useRef(LoginValidateColumn);
  const [showLoader, setShowLoader] = useState(false);
  const [alertShow, setAlertShow] = useState(false);

  useEffect(() => {
    checkUserIdentifier(navigate, dispatch);
    setloginModel((prevUserData) => ({
      ...prevUserData,
      ["type"]: "userid",
    }));
  }, []);

  const loginModelEdit = (e) => {
    const { name, value } = e.target;
    setloginModel((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));

    var elementName = e.target.name;
    modelFields(elementName, e.target.value);
  };

  const modelFields = (elementName, elementValue) => {
    switch (elementName) {
      case "userId":
        checkValidation(elementValue, loginValidation.current[0]);
        break;
      case "password":
        checkValidation(elementValue, loginValidation.current[1]);
        break;
      case "otp":
        checkValidation(elementValue, loginValidation.current[2]);
        break;
      default:
        break;
    }
  };

  const checkValidation = (elementValue, loginValidationField) => {
    if (elementValue == null || elementValue == "") {
      loginValidationField.isSuccess = false;
      loginValidationField.errorMessage = GetValidationMessage("Required");
    } else if (loginValidationField.isSuccess == false) {
      loginValidationField.isSuccess = true;
      loginValidationField.errorMessage = "";
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    debugger
    LoginValidateColumn.map((key) => {
      switch (key.fieldName) {
        case "userId":
          checkValidation(loginModel.userId, loginValidation.current[0]);
          break;
        case "password":
          if(loginModel.type=="userid")
          checkValidation(loginModel.password, loginValidation.current[1]);
          break;
        case "otp":
          if(loginModel.type=="otp")
          checkValidation(loginModel.otp, loginValidation.current[2]);
          break;
        default:
          break;
      }
    });
    let isFormValid = true;
    loginValidation.current.filter((key) => {
      if (key.isSuccess == false) return (isFormValid = false);
    });

    if (isFormValid) {
      setShowLoader(true);
      var response = await checkLogin(loginModel);
      if (response != null && response.status == 200 && response.data != null) {
        setShowLoader(false);
        setUserKey(response.data.loginToken);
        setDataAfterLogin(response.data);
      } else {
        setShowLoader(false);
        setAlertShow(true);
      }
    } else {
      setloginModel((prevUserData) => ({
        ...prevUserData,
        ["isValid"]: isFormValid,
      }));
    }
  };

  const setDataAfterLogin = (data) => {
    dispatch(setUserDetail(data));
    navigate("/");
  };
 const setLoginType=(event)=> {
  let userType="otp";
  if(event.target.id=="rb_userid" && event.target.value=="on")
    userType= "userid";

    setloginModel((prevUserData) => ({
      ...prevUserData,
      ["type"]: userType,
    }));
  }
  return (
    <>
      <main>
        <section class="vh-xxl-100" className="bgsection">
          <div class="container h-100 px-0 px-sm-4">
            <div class="row justify-content-center align-items-center m-auto">
              <div class="col-6">
                <div class="bg-mode shadow rounded-3 overflow-hidden">
                  <div class="row g-0">
                    <div class="p-4 p-sm-7">
                      <Link to="/">
                        <h3 class="m-b-20">
                          <i class="bi bi-arrow-left"></i>
                          <img
                            class="h-50px mb-4"
                            src="logo.svg"
                            alt="logo"
                          ></img>
                        </h3>
                      </Link>

                      <h1 class="mb-2 h3">Welcome back</h1>
                      <p class="mb-0">
                        New here?{" "}
                        <Link to="/Register">
                          <span class="nav-text">Register Here</span>
                        </Link>
                      </p>
                      <div onChange={setLoginType}>
                      <div class="col-5 mt-2">
                      <input
                          id="rb_userid"
                            class="radiobtn"
                            type="radio"
                            name="type"
                            defaultChecked
                          />
                          &nbsp; UserId                        
                        <div style={{ float: "right" }}>
                        <input
                        id="rb_otp"
                          class="radiobtn"
                          type="radio"
                          name="type"                          
                        />
                        &nbsp; Otp
                        </div>
                      </div>
                      </div>
                      <form
                        class="mt-4 text-start"
                        method="POST"
                        onSubmit={handleLoginSubmit}
                      >
                        <div class="mb-3">
                          <label class="form-label">
                            Enter Email or Mobile Number
                            <span class="text-danger">*</span>
                            {loginValidation.current[0].isSuccess == false && (
                              <span class="text-danger">
                                This field is Required
                              </span>
                            )}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name="userId"
                            value={loginModel.userId}
                            onChange={loginModelEdit}
                          ></input>
                        </div>

                        {loginModel.type == "userid" && (
                          <div class="mb-3 position-relative">
                            <label class="form-label">
                              Password <span class="text-danger">*</span>
                              {loginValidation.current[1].isSuccess ==
                                false && (
                                <span class="text-danger">
                                  This field is Required
                                </span>
                              )}
                            </label>
                            <input
                              type="password"
                              class="form-control"
                              name="password"
                              value={loginModel.password}
                              onChange={loginModelEdit}
                            ></input>

                            <span class="position-absolute top-50 end-0 translate-middle-y p-0 mt-3">
                              <i class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2"></i>
                            </span>
                          </div>
                        )}

                        {loginModel.type == "otp" && (
                          <div class="mb-3 position-relative">
                            <label class="form-label">
                              Enter Otp <span class="text-danger">*</span>
                              {loginValidation.current[2].isSuccess ==
                                false && (
                                <span class="text-danger">
                                  This field is Required
                                </span>
                              )}
                            </label>
                            <input
                              class="form-control"
                              name="otp"
                              value={loginModel.otp}
                              onChange={loginModelEdit}
                            ></input>
                          </div>
                        )}

                        <div class="mb-3 d-sm-flex justify-content-between">
                          <div>
                            <input
                              type="checkbox"
                              class="form-check-input"
                              id="rememberCheck"
                            ></input>
                            <label class="form-check-label" for="rememberCheck">
                              Remember me?
                            </label>
                          </div>
                          <a href="forgot-password.html">Forgot password?</a>
                        </div>
                        <div>
                          <button
                            type="submit"
                            class="btn btn-primary w-100 mb-0"
                            disabled={showLoader}
                          >
                            {showLoader == false && <span> Sign In</span>}
                            {showLoader == true && (
                              <span>
                                {" "}
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>{" "}
                                &nbsp; Verifying...
                              </span>
                            )}
                          </button>
                        </div>
                        <div class="position-relative my-4">
                          <hr></hr>
                          <p class="small bg-mode position-absolute top-50 start-50 translate-middle px-2">
                            Or sign in with
                          </p>
                        </div>

                        <div class="vstack gap-3">
                          <a href="#" class="btn btn-light mb-0">
                            <i class="fab fa-fw fa-google text-google-icon me-2"></i>
                            Sign in with Google
                          </a>
                          <a href="#" class="btn btn-light mb-0">
                            <i class="fab fa-fw fa-facebook-f text-facebook me-2"></i>
                            Sign in with Facebook
                          </a>
                        </div>
                        <p class="mb-0 mt-3 text-center">
                          ©2022{" "}
                          <a target="_blank" href="https://www.webestica.com/">
                            Webestica.
                          </a>{" "}
                          All rights reserved
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
export default Login;
