import { React } from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <>
      <section style={{ paddingTop: "3.5rem" }} className="bgsection"></section>
      <section className="pt-0 pt-lg-5">
        <div class="container">
          <div class="row mb-4 mb-md-5">
            <div class="card">
              <div class="card-body">
                <div class="col-12">
                  <h3>Privacy Policy</h3>
                  <p>
                    {" "}
                    DigitalAppZone along with its affiliates and partners value
                    your trust &amp; respect your privacy. This Privacy Policy
                    provides you with details about the manner in which your
                    data is collected, stored &amp; used by us. You are advised
                    to read this Privacy Policy carefully before using
                    DigitalAppZone Website/ App/ Services. By visiting GPL’s
                    website/WAP site/applications you expressly give us consent
                    to use &amp; disclose your personal information in
                    accordance with this Privacy Policy. If you do not agree to
                    the terms of the policy, please do not use or access
                    DigitalAppZone’s website, WAP site, mobile applications
                    &amp; Services. Our privacy policy may change at any time
                    without prior notification. To make sure that you are aware
                    of any changes, kindly review the policy periodically. This
                    Privacy Policy shall apply uniformly to GPL desktop website,
                    GPL mobile WAP site &amp; GPL mobile applications.
                  </p>

                  <h4>Information We Collect</h4>
                  <br></br>
                  <p>
                    When registering on our Website/ App, as appropriate, you
                    may be asked to provide few details which consists of but
                    not limited to name, address, occupation, mobile numbers,
                    postal code, domain details, IP address, mac address,
                    browser details, Cookies, computer/ laptop/ smart device
                    etc. This information is required for the purpose of
                    disposal of orders placed by user, as well as, for the
                    purpose of verification process of its customers. While
                    sharing personal information, you recognize and understand
                    that there is no compulsion on you to provide us with your
                    personal information and any and all personal information
                    provided by you to us is with your full consent, own
                    volition and desire to provide such personal information.
                    You also understand that we are under no obligation to
                    verify the source from which the personal information about
                    you is provided to us, and they are deemed to be provided by
                    you. We collect this information when you use our website/
                    register to our website/ App / create account on our
                    website/ app/ submit form/ send enquiry or any other means.
                  </p>
                  <p>
                    The user hereby further acknowledges that DigitalAppZone
                    reserves all the rights to preserve and retain the login
                    password of account which he/she holds with DigitalAppZone,
                    including any communication in whatsoever with the user
                    either, verbal, telephonic, sms, webchat, video
                    conferencing, emails, etc., for its internal training,
                    process improvements, research and developments etc.
                  </p>
                  <p>
                    The user hereby understands and acknowledges that
                    DigitalAppZone does not owe any responsibility for the
                    authenticity, genuinity and legitimacy or updating of the
                    content of the third party information provided on the
                    DigitalAppZone website, and the user will verify such third
                    party information from its respective organization, before
                    relying upon the same.
                  </p>
                  <p>
                    The user hereby understands and acknowledges that the
                    DigitalAppZone does not owe any responsibility for any
                    spamming, phishing, etc., including any attempt or
                    commission of any cyber or security threat or offence, or
                    theft of data etc. based upon access to DigitalAppZone
                    website and availing its services, by any miscreant or third
                    party. The DigitalAppZone does not owe any responsibility
                    for such theft or illegal leakage or disclosure of the same
                    by any miscreant, for which the user understands and
                    acknowledges that he avails the service and use of
                    DigitalAppZone’s website at his own risks, costs, and
                    consequences.
                  </p>
                  <p>
                    The user hereby acknowledges that DigitalAppZone reserves
                    all the rights to collect, preserve, and retain all personal
                    data of its user and have all the right to utilize said data
                    for its own internal assessment, research and development,
                    etc, and shall also have all the right to use and share the
                    same for its own usage and purposes, for itself or for any
                    Third Party.
                  </p>
                  <h4>Data Security </h4>
                  <p>
                    {" "}
                    We employ industry-standard security measures to protect
                    your personal information from unauthorized access,
                    disclosure, alteration, and destruction. We regularly review
                    and update our security practices to ensure the ongoing
                    integrity of our systems.
                  </p>
                  <h4>Use of Personal Information</h4>
                  <br></br>
                  <p>
                    We use personal information to provide you with services
                    &amp; products you explicitly requested for, to resolve
                    disputes, troubleshoot concerns, help promote safe services,
                    collect money, measure consumer interest in our services,
                    inform you about offers, products, services, updates,
                    customize your experience, detect &amp; protect us against
                    error, fraud and other criminal activity, enforce our terms
                    and conditions, etc. We also use your contact information to
                    send you offers based on your previous orders and interests.
                    We may occasionally ask you to complete optional online
                    surveys. These surveys may ask you for contact information
                    and demographic information (like zip code, age, gender,
                    etc.). We use this data to customize your experience at
                    DigitalAppZone, providing you with content that we think you
                    might be interested in and to display content according to
                    your preferences.
                  </p>

                  <p>We may use the information:</p>
                  <ul>
                    <li>
                      To follow up after correspondence (email or phone
                      inquiries);
                    </li>
                    <li>To understand your requirements;</li>
                    <li>To send you promotional emails;</li>
                    <li>
                      To research and update the services as per your
                      requirement;
                    </li>
                    <li>To serve you better.</li>
                  </ul>

                  <h4>Consent</h4>
                  <br></br>
                  <p>
                    Whenever you visit/ use DigitalAppZone’s website/ Mobile
                    App, DigitalAppZone may ask you some Information. If you
                    fill such information, it will be presumed that you wish to
                    Communicate with DigitalAppZone and have given consent to
                    DigitalAppZone to receive communications from
                    DigitalAppZone. DigitalAppZone will communicate with you
                    through e-mail or Call or SMS or by posting notices on the
                    website/ App or by such other means as DigitalAppZone may
                    determine from time-to-time. You agree that all agreements,
                    notices, disclosures and other communications that
                    DigitalAppZone provides to you satisfy any legal requirement
                    that such communications be in writing, to the extent
                    permitted by applicable law. If you are sending enquiry to
                    DigitalAppZone or applying in case of Job Opening at
                    DigitalAppZone which DigitalAppZone announces time to time
                    through different medium/ portals, you are agreeing to
                    receive our notification through Email/ SMS/ Call/ or by any
                    other medium which DigitalAppZone deemed appropriate.
                  </p>
                  <p>
                    In furtherance to your usage of the Website/ App/ Services,
                    you expressly waive the Do Not Call (DNC) / Do Not Disturb
                    (DND) registrations on your phone/mobile numbers for
                    contacting you for this purpose. Hence, there is no DNC /
                    DND check required for the number you have left on our
                    Website. Such modes of contacting you may include sending
                    SMSs, email alerts and / telephonic calls/ any other medium
                    which DigitalAppZone deemed appropriate.
                  </p>

                  <h4>Cookies</h4>
                  <br></br>
                  <p>
                    A “cookie” is a small piece of information stored by a web
                    server on a web browser so it can be later read back from
                    that browser. DigitalAppZone uses cookie and tracking
                    technology depending on the features offered. No personal
                    information will be collected via cookies and other tracking
                    technology; however, if you previously provided personally
                    identifiable information, cookies may be tied to such
                    information. Aggregate cookie and tracking information may
                    be shared with third parties.
                  </p>

                  <h4>Third Party Websites/ Links</h4>
                  <br></br>
                  <p>
                    From time to time, this website may also include links to
                    other sites. These links are provided for your convenience
                    to provide further information. They do not signify that we
                    endorse the website(s). We have no responsibility for the
                    content of the linked website(s). Your use of Third-Party
                    sites is subject to the terms of use and privacy policies
                    located on such Third-Party sites, which may be different
                    from these Terms &amp; Conditions or GPL’s Privacy Policy.
                  </p>

                  <h4>Links to Other Sites</h4>
                  <br></br>
                  <p>
                    Our website/ App link to other websites that may collect
                    personally identifiable information about you.
                    DigitalAppZone is not responsible for the privacy practices
                    or the content of those linked websites.
                  </p>

                  <h4>Confidentiality</h4>
                  <br></br>
                  <p>
                    The customer is liable to choose and set a strong password/
                    passcode while registering for the portal and is fully
                    accountable for maintaining password confidentiality always.
                    DigitalAppZone, as per the scope permitted by law, holds all
                    the rights to access and share the customer information
                    provided for portal registration.
                  </p>

                  <h4>
                    Disclosure and limitation on application of principles
                  </h4>
                  <br></br>
                  <p>
                    We may share Your Personal data which we hold with our
                    associates/ affiliate partners/ subsidiaries, which mean our
                    subsidiaries, our ultimate holding company/ partners and its
                    subsidiaries. We may be required to disclose your Personal
                    Information in response to a lawful request by public
                    authorities, including to meet national security or law
                    enforcement requirements of the country in which we operate.
                  </p>

                  <h4>Security</h4>
                  <br></br>
                  <p>
                    DigitalAppZone has stringent security measures in place to
                    protect the loss, misuse, and alteration of the information
                    under our control. Whenever you change or access your
                    account information, we offer the use of a secure server.
                    Once your information is in our possession we adhere to
                    strict security guidelines, protecting it against
                    unauthorized access.
                  </p>

                  <h4>Retention of Data</h4>
                  <br></br>
                  <p>
                    DigitalAppZone requires, under applicable laws, to retain
                    certain records for a period as suggested by regulatory
                    authorities even after closure of customer’s DigitalAppZone
                    account, which will include customer’s personal data such as
                    name, contact details, customer number and transaction
                    history, ("Retained Data"). Other than the Retained Data,
                    DigitalAppZone will delete and destroy all Personal
                    Information that it hold about customer when customer
                    terminate DigitalAppZone account. GPL does not store online
                    banking login PIN, and/or password. Please note that GPL
                    will not store credit card information after the closure of
                    your account.
                  </p>
                  <p>
                    Please note that DigitalAppZone will never ask customer to
                    disclose his/ her personal or security details by e-mail. If
                    you receive any e-mail purportedly from us requesting your
                    personal or security details, please do not respond to it
                    ("Such E-mail"). Please forward any Such E-mail to
                    support@DigitalAppZone.com and thereafter delete the e-mail
                    immediately.
                  </p>

                  <h4>Communication</h4>
                  <br></br>
                  <p>
                    We may contact you via the e-mail address and phone number
                    (electronic mail/ calls/ SMS) registered with your
                    DigitalAppZone Account. You may also receive
                    system-generated transactional e-mails such as
                    confirmations, notification of receipt of payments,
                    notification of password changes etc. which are necessary
                    for the proper operation and administration of your
                    DigitalAppZone Account. You expressly consent to receive
                    such communications from us, irrespective of whether your
                    phone number is registered on the National Do Not Call
                    Registry.
                  </p>
                  <p>
                    As DigitalAppZone’s registered user, you will occasionally
                    receive information by e-mail/ calls from us, unless you
                    have expressly chosen not to receive such communication,
                    about products, services and special deals which we think
                    will be of interest to you via our newsletter. You can
                    change whether or not you receive newsletters from us.
                    However please note that you will still receive
                    communication regarding your DigitalAppZone Ltd account such
                    as transactional e-mails or other notifications affecting
                    the operation of your DigitalAppZone account or our legal
                    relationship.
                  </p>
                  <p>
                    In case, you have sent us Enquiry/ or have created account
                    at DigitalAppZone Website/ App or you are registered at
                    DigitalAppZone Web/ App, then you are agreeing to receive
                    promotional offers/ products related information from
                    DigitalAppZone Ltd or its affiliate partners.
                  </p>

                  <h4>Modification in Policy</h4>
                  <br></br>
                  <p>
                    The user hereby understands and acknowledges that
                    DigitalAppZone reserves its right to change, amend, modify
                    or alter any or all the clauses of this policy with
                    retrospective effect, without giving any prior notice.
                    DigitalAppZone has right to terminate the access of its
                    website at any point on misuse of its content by the
                    individuals. The user of this website by having access of
                    the same acknowledges that he will always remain bound by
                    these terms and condition, mentioned here in above.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
