export const IoTSettingModel={
    id:0,
    deviceId:'',
    deviceName:'',
    ipAddress:'',
    url:'',
    iotControls:[],
    isValid:true
}

export const IoTUserDevice={
    userDeviceId:'',
    userControls:[]
}