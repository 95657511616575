import React, { useState, useEffect, useRef } from "react";
import SignalRService from "../Services/SignalRService";
function VideoStream() {
  const [isStream, setIsStream] = useState(false);
  const localVideoRef = useRef(null);
  //const peerConnection = new RTCPeerConnection();
  //  const [webRtcHub, setWebRtcHub] = useState(null);
  const remoteVideoRef = useRef(null);
  const configuration = {
    iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
  };
  let peerConnection = new RTCPeerConnection(configuration);
  let stream = null;
  useEffect(() => {
    peerConnection.onicecandidate = (event) => { 
      if (event.candidate!=null) {     
        SignalRService.sendIceCandidate(JSON.stringify(event.candidate));
      }
    };
    peerConnection.ontrack = (event) => {
      // let remoteVideo = document.getElementById("remoteVideoRef");
      // remoteVideo.srcObject = event.streams[0];
      remoteVideoRef.current.srcObject = event.streams[0];
      //setIsStream(true);
    };

    SignalRService.receiveOffer((offer, senderConnectionId) => {
      const remoteOffer = new RTCSessionDescription(JSON.parse(offer));
      peerConnection.setRemoteDescription(remoteOffer);
      // Create and send an answer back to the remote peer
      peerConnection
        .createAnswer()
        .then((answer) => peerConnection.setLocalDescription(answer))
        .then(() => {
          SignalRService.sendAnswer(
            JSON.stringify(peerConnection.localDescription),
            senderConnectionId
          );
        })
        .catch((error) => {
          console.error("Error creating answer:", error);
        });
    });

    SignalRService.receiveAnswer((answer) => {
      const remoteAnswer = new RTCSessionDescription(JSON.parse(answer));
      peerConnection.setRemoteDescription(remoteAnswer);
    });

    SignalRService.receiveIceCandidate((iceCandidate) => {
      const remoteCandidate = new RTCIceCandidate(JSON.parse(iceCandidate));
      peerConnection.addIceCandidate(remoteCandidate);
    });

    return () => {
      SignalRService.stopConnection();
      // Clean up resources when the component is unmounted
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      if (peerConnection) {
        peerConnection.close();
      }
    };
  }, []);

  useEffect(() => {
    SignalRService.startConnection();
  });

  const startStreaming = async() => {
    stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    let localVideo = document.getElementById("localVideoRef");
    localVideo.srcObject = stream;

    peerConnection.addStream(stream);
   
    stream.getTracks().forEach((track) => {
      peerConnection.addTrack(track, stream);
    });
  };

  const startCall = () => {
    // await startStreaming();
    peerConnection
      .createOffer()
      .then((offer) => peerConnection.setLocalDescription(offer))
      .then(() => {
        SignalRService.sendOffer(
          JSON.stringify(peerConnection.localDescription)
        );
      })
      .catch((error) => {
        console.error("Error creating offer:", error);
      });
  };
  // Listen for connectionstatechange on the local RTCPeerConnection
  peerConnection.addEventListener("connectionstatechange", (event) => {
    if (peerConnection.connectionState === "connected") {
      // Peers connected!
    }
  });

  return (
    <div>
      <div>
        <label>Sender</label>
        <video
          width="250"
          height="250"
          id="localVideoRef"
          ref={localVideoRef}
          autoPlay
        ></video>
      </div>
      <button class="btn btn-primary" onClick={startStreaming}>
        Start Video
      </button>
      <button class="btn btn-primary" onClick={startCall}>
        Start Call
      </button>

      <div>
        <label>Receiver:</label>
        <video
          width="250"
          height="250"
          id="remoteVideoRef"
          ref={remoteVideoRef}
          muted
          autoPlay
          playsInline
        ></video>
      </div>
    </div>
  );
}

export default VideoStream;
