import {React,useState,useEffect, useRef} from 'react';
import { Routes, Route, Link,useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import RegisterModel from '../Models/Register-Model'
import {checkValidation} from '../common'
import {addNewUser} from '../Services/Service'
import {GetValidationMessage,RegisterValidateColumn} from '../Models/validationFields';
//import { data } from 'jquery';
function Register(){
	const navigate = useNavigate()
    const [regiterModel, setRegisterModel] = useState(RegisterModel);
	const [showLoader, setShowLoader] = useState(false);
	const registerValidation = useRef(RegisterValidateColumn);

	const regiterModelEdit = (e) => {
		const { name, value } = e.target;		
		setRegisterModel((prevUserData) => ({
		  ...prevUserData,
		  [name]: value		 			
		}));
		const validationModel=[];
		checkValidation(e.target.name,e.target.value,validationModel)
		registerValidation.current.map((k)=>{
			var model=	validationModel.find((key)=>key.fieldName==k.fieldName);
			if(model!=undefined && model!=null)
			{
				k.isSuccess=model.isSuccess;
				k.errorMessage=model.errorMessage;
			}			
		})	
	  };

	  

	const handleRegisterSubmit = async (event) => {
		event.preventDefault();	
		const validationModel=[];
		RegisterValidateColumn.map((key)=>{
			switch(key.fieldName){
				case "firstName":checkValidation(key.fieldName,regiterModel.firstName,validationModel);
				                  break; 	
				case "lastName":checkValidation(key.fieldName,regiterModel.lastName,validationModel);	
			                 	break; 	
				case "password":checkValidation(key.fieldName,regiterModel.password,validationModel);
				                break; 	
				case "confirmPassword":checkValidation(key.fieldName,regiterModel.confirmPassword,validationModel);	
				default: break; 
			}	
		});

		 registerValidation.current.map((k)=>{
			var model=	validationModel.find((key)=>key.fieldName==k.fieldName);
			if(model!=undefined && model!=null)
			{
				k.isSuccess=model.isSuccess;
				k.errorMessage=model.errorMessage;
			}
		})	

		let isFormValid=true;
			validationModel.filter((key)=>{
				if(key.isSuccess==false)
				  return isFormValid=false;			  
			});		

		if(isFormValid)
		{
			if((regiterModel.contactNumber == undefined || regiterModel.contactNumber == '') && (regiterModel.emailId == undefined || regiterModel.emailId == '') )
			{
			   isFormValid=false;
			   toast.warn(GetValidationMessage('EmailOrMobileRequired'), {
				position: toast.POSITION.BOTTOM_RIGHT
			});			   
			}
			if(isFormValid && regiterModel.password != regiterModel.confirmPassword)
			{
			   isFormValid=false;
			   toast.warn(GetValidationMessage('PasswordUnmatch'), {
				position: toast.POSITION.BOTTOM_RIGHT
			});			   
			}
		}
		
		if(isFormValid)
		{		
		var response=await addNewUser(regiterModel);
		if(response.status==200 && response.data!=null)
		{
		 navigate('/Login');   
		}	
	}
	else{
		setRegisterModel((prevUserData) => ({
			...prevUserData,
			['isValid']: isFormValid		 			
		  }));
	}
	  };

    return ( 
		<main>
			<ToastContainer />
		<section class="vh-xxl-100"  className='bgsection'>
			<div class="container h-100 px-0 px-sm-4">
				<div class="row justify-content-center align-items-center m-auto">
					<div class="col-6">
						<div class="bg-mode shadow rounded-3 overflow-hidden">
							<div class="row g-0">								
									<div class="p-4 p-sm-6">										
										<Link to="/">
										<h3 class="m-b-20">        
          <i class="bi bi-arrow-left"></i>
		  <img class="h-50px mb-4" src="logo.svg" alt="logo"></img>
          </h3>											
										</Link>										
										<h1 class="mb-2 h3">Create new account</h1>
										<p class="mb-0">Already a member?  <Link to="/Login">                           
                            <span class="nav-text">Login</span>
                        </Link>.</p>			
									
										<form class="mt-4 text-start"  method="POST"  onSubmit={handleRegisterSubmit}>									
											
							<div class="mb-3">
								<label class="form-label">First Name <span class="text-danger">*</span>							
								{ registerValidation.current[0].isSuccess==false && <span class="text-danger">{registerValidation.current[0].errorMessage}</span>}
								</label>
								<input type="text" class="form-control" name="firstName" maxLength="25" value={regiterModel.firstName}
                          onChange={regiterModelEdit}  />
							</div>
							<div class="mb-3">
								<label class="form-label">Last Name <span class="text-danger">*</span>
							{ registerValidation.current[1].isSuccess==false && <span class="text-danger">{registerValidation.current[1].errorMessage}</span>}
							</label>
								<input type="text" class="form-control" maxLength="25" name="lastName" value={regiterModel.lastName}
                          onChange={regiterModelEdit}  />
							</div>
							<div class="mb-3">
								<label class="form-label">Mobile Number </label>
								<input type="text" class="form-control" maxLength="10" name="contactNumber" value={regiterModel.contactNumber}
                          onChange={regiterModelEdit} />
							</div>
							<div class="mb-3">
								<label class="form-label">Email Address </label>
								<input type="text" class="form-control" maxLength="50" name="emailId" value={regiterModel.emailId}
                          onChange={regiterModelEdit}/>
							</div>	
							<div class="mb-3">
								<label class="form-label">Password <span class="text-danger">*</span>
								{ registerValidation.current[2].isSuccess==false && <span class="text-danger">{registerValidation.current[2].errorMessage}</span>}
								</label>
								<input type="password" class="form-control" maxLength="30" name="password" value={regiterModel.password}
                          onChange={regiterModelEdit}/>
							</div>	
							<div class="mb-3">
								<label class="form-label">Confirm Password <span class="text-danger">*</span>
								{ registerValidation.current[3].isSuccess==false && <span class="text-danger">{registerValidation.current[3].errorMessage}</span>}
								</label>
								<input type="password" class="form-control" maxLength="30" name="confirmPassword" value={regiterModel.confirmPassword}
                          onChange={regiterModelEdit} />
							</div>									

											<div><button type="submit" class="btn btn-primary w-100 mb-0">Sign up</button></div>
				
											<div class="position-relative my-4">
												<hr></hr>
												<p class="small position-absolute top-50 start-50 translate-middle bg-mode px-1 px-sm-2">Or sign in with</p>
											</div>
				
											<div class="vstack gap-3">
												<a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-google text-google-icon me-2"></i>Sign in with Google</a>
												<a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-facebook-f text-facebook me-2"></i>Sign in with Facebook</a>
											</div>
				
											<p class="mb-0 mt-3 text-center">©2022 <a target="_blank" href="https://www.webestica.com/">Webestica.</a> All rights reserved</p>
										</form>									
									</div>		
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		</main>
    )
}
export default Register;