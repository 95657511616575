import GlobalSetting from "./config";
import { GetValidationMessage } from "./Models/validationFields";
import {
  GetUserDetailbyId,
  GetBusinessTypes,
  GetBusinessDetail,
} from "./Services/Service";
import { setUserDetail } from "./Slices/userSlice";
import SignalRService from "./Services/SignalRService";
export const setUserKey = (identifier) => {
  let key = GlobalSetting.userKey;
  localStorage.setItem(key, identifier);
};

export const getUserKey = () => {
  let identifier = localStorage.getItem(GlobalSetting.userKey);
  return identifier;
};

export const getImageUrl = (imgUrl) => {
  return GlobalSetting.BASE_API_URL +'Uploads/' + imgUrl;
};

export const convertDatetime = (dateTimeValue) => {
  var date = new Date(dateTimeValue);
  var day = date.getDate(); // yields date
  var month = date.getMonth() + 1; // yields month (add one as '.getMonth()' is zero indexed)
  var year = date.getFullYear(); // yields year
  var hour = date.getHours(); // yields hours
  var minute = date.getMinutes(); // yields minutes
  var second = date.getSeconds(); // yields seconds

  // After this construct a string with the above results as below
  var formattedDateTime =
    day + "/" + month + "/" + year + " " + hour + ":" + minute + ":" + second;
  return formattedDateTime;
};

export const getMessageModel = (
  keyValue,
  messageBodyValue,
  descriptionValue
) => {
  let msgModel = {
    key: keyValue,
    messageBody: messageBodyValue,
    description: descriptionValue,
  };
  return msgModel;
};

export const checkValidation = (elementName, elementValue, validationModel) => {
  if (elementValue == null || elementValue == "")
    validationModel.push({
      fieldName: elementName,
      isSuccess: false,
      errorMessage: GetValidationMessage("Required"),
    });
  else
    validationModel.push({
      fieldName: elementName,
      isSuccess: true,
      errorMessage: "",
    });
};

export const showSaveLoader = (setShowLoader, isVisible) => {
  setShowLoader((prevUserData) => ({
    ...prevUserData,
    saveLoader: isVisible,
  }));
};

export const showListLoader = (setShowLoader, isVisible) => {
  setShowLoader((prevUserData) => ({
    ...prevUserData,
    listLoader: isVisible,
  }));
};

export const checkUserIdentifier = async (navigate, dispatch) => {
  let identifier = getUserKey();
  if (identifier != undefined && identifier != null && identifier != "") {
    getUserDetail(navigate, dispatch);
  }
};

const getUserDetail = async (navigate, dispatch) => {
  var response = await GetUserDetailbyId();
  if (response != null && response.status == 200 && response.data != null) {
    dispatch(setUserDetail(response.data));
    SignalRService.startConnection();
    navigate("/");
  }
};

export const getBusinessTypes = async (
  setBusinessProfile,
  isloaderShow,
  setShowLoader
) => {
  if (isloaderShow) showListLoader(setShowLoader, true);
  var response = await GetBusinessTypes();
  if (response != null && response.status == 200 && response.data != null) {
    if (isloaderShow) showListLoader(setShowLoader, false);
    setBusinessProfile((prevData) => ({
      ...prevData,
      ["businessTypeList"]: response.data,
    }));
  } else {
    if (isloaderShow) showListLoader(setShowLoader, false);
  }
};
