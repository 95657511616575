import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { IoTSettingModel, IoTUserDevice } from "../Models/IoT";
import {
  GetDevices,
  GetIoTSetting,
  SaveIoTSetting,
  UpdateIoTControl,
} from "../Services/Service";

const IoTSetting = () => {
  const [selectedControl, setSelectedControl] = useState("");
  const [controls, setControls] = useState([]);
  const [iotSetting, setIoTSetting] = useState(IoTSettingModel);
  const [userDevice, setUserDevice] = useState(IoTUserDevice);
  const [editRow, setEditMode] = useState(null);

  useEffect(() => {
    getDevices();
  }, [userDevice.userDeviceId]);

  const getDevices = async () => {
    var response = await GetDevices();
    if (
      response != null &&
      response.status == 200 &&
      response.data != null &&
      response.data.length > 0
    ) {
      setUserDevice({ ...userDevice, userControls: response.data });
      getIoTSetting(response.data[0].deviceId);
    }
  };

  const userDeviceEdit = (e) => {
    const { name, value } = e.target;
    setUserDevice((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    getIoTSetting(value);
  };

  const getIoTSetting = async (deviceId) => {
    var response = await GetIoTSetting(deviceId);
    if (response != null && response.status == 200 && response.data != null) {
      setIoTSetting(response.data);
      if (response.data.iotControls != null && response.data.iotControls != "")
        setControls(JSON.parse(response.data.iotControls));
    }
  };

  const handleControlChange = (e) => {
    setSelectedControl(e.target.value);
  };

  const handleAddControl = () => {
    if (selectedControl) {
      let controlEventType = "",
        controlName = "",
        controlValue = "",
        controlState = "";
      if (selectedControl == "button") {
        controlEventType = "jsMessage";
        controlName = "Button";
      } else if (selectedControl == "toggleButton") {
        controlName = "Toggle_Button";
        controlValue = "0,1";
        controlState = "0";
      } else if (selectedControl == "messageButton") {
        controlName = "Message_Button";
      }
      let ctl = {
        type: selectedControl,
        eventType: controlEventType,
        name: controlName,
        value: controlValue,
        state: controlState,
        sortOrder: controls.length + 1,
      };
      setControls([...controls, ctl]);
      setSelectedControl("");
    }
  };

  const removeRow = (index) => {
    let control = controls[index];
    let controlDetail = controls.filter((x) => x != control);
    setControls(controlDetail);
    if (iotSetting.id > 0) handleUpdateControl(controlDetail);
  };

  const handleUpdateControl = async (controlDetail) => {
    let iotPayload = {
      id: iotSetting.id,
      controls: controlDetail.length > 0 ? JSON.stringify(controlDetail) : "",
    };
    var response = await UpdateIoTControl(iotPayload);
    if (
      response != null &&
      response.status == 200 &&
      response.data.response_text != null
    ) {
      toast.success(response.data.response_text, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const editControl = (action, control, index) => {
    if (action == "editname") {
      //if (control == "button")
      setEditMode("txt_btn_name" + index);
    } else if (action == "cancelname") setEditMode(null);
    else if (action == "editvalue") {
      //if (control == "button")
      setEditMode("txt_btn_value" + index);
    } else if (action == "cancelvalue") setEditMode(null);
  };

  const handleInputChange = (action, control, e, index) => {
    if (index !== -1) {
      // if (control == "button") {
      const updatedArray = [...controls];
      updatedArray[index] = {
        ...updatedArray[index],
        [action]: e.target.value,
      };
      setControls(updatedArray);
      // }
    }
  };

  const testControl = (control, e, index) => {    
    if (index !== -1) {
      let testVal = null;
      if (control.type == "button" || control.type == "messageButton") {
        testVal = control.value;
      } else if (control.type == "toggleButton") {
        let arr = control.value.split(",");
        if (e.target.checked == true) {
          testVal = arr[1];
        } else {
          testVal = arr[0];
        }
      }
      let messageObj = {
        requestType: control.eventType,
        messageText:
          control.type == "button" ? testVal : control.name + ":" + testVal,
      };
      //let message = control.name + ":" + testVal;
      let message = JSON.stringify(messageObj);

      Swal.fire({
        title: "Control Value",
        text: message,
      });
    }
  };

  const iotDetailEdit = (e) => {
    const { name, value } = e.target;
    setIoTSetting((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    let iotPayload = iotSetting;
    iotPayload.iotControls = JSON.stringify(controls);
    var response = await SaveIoTSetting(iotPayload);
    if (
      response != null &&
      response.status == 200 &&
      response.data.response_text != null
    ) {
      toast.success(response.data.response_text, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div class="container mt-5 vstack gap-4">
        <div class="row" style={{ background: "#c8e3fa" }}>
          <div class="col-12 mb-4 mb-sm-1">
            <div
              class="d-sm-flex justify-content-between align-items-center"
              style={{ marginTop: "1% !important" }}
            >
              <h4 class="h4 mb-2 mb-sm-0">IoT Setting</h4>
            </div>
          </div>
        </div>
        <div class="row" style={{ background: "#f7f7f7" }}>
          <div class="col-12 mt-4 mb-4 mb-sm-5">
            <div class="row mb-3">
              <div class="col-3">
                <label class="form-label">Select DeviceId</label>
                <select
                  className="form-select js-choice form-control form-control-lg me-1"
                  data-search-enabled="true"
                  name="userDeviceId"
                  value={userDevice.userDeviceId}
                  onChange={userDeviceEdit}
                >
                  {userDevice.userControls.map((k) => {
                    return <option value={k.deviceId}>{k.deviceName}</option>;
                  })}
                </select>
              </div>
            </div>
            <hr></hr>
            <div class="row">
              <div class="col-3">
                <label class="form-label">Add New Device</label>
                <select
                  class="form-control"
                  name="deviceName"
                  value={iotDetailEdit.deviceName}
                  onChange={iotDetailEdit}
                >
                  <option value="Esp32">Esp32</option>
                  <option value="Esp32 Cam">Esp32 Cam</option>
                </select>
              </div>

              <div class="col-3">
                <label>Device Id</label>
                <input
                  class="form-control"
                  placeholder="Enter Device Id"
                  name="deviceId"
                  value={iotSetting.deviceId}
                  onChange={iotDetailEdit}
                ></input>
              </div>

              <div class="col-3">
                <label>Device IP Address</label>
                <input
                  class="form-control"
                  placeholder="Enter IP Address"
                  name="ipAddress"
                  value={iotSetting.ipAddress}
                  onChange={iotDetailEdit}
                ></input>
              </div>

              <div class="col-3">
                <label>Url</label>
                <input
                  class="form-control"
                  placeholder="Enter Url"
                  name="url"
                  value={iotSetting.url}
                  onChange={iotDetailEdit}
                ></input>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-3">
                <label>Select Control: </label>
                <select
                  class="form-control"
                  value={selectedControl}
                  onChange={handleControlChange}
                >
                  <option value="">Select</option>
                  <option value="toggleButton">Toggle Button</option>
                  <option value="button">Button</option>
                  <option value="messageButton">Message Button</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div class="col-2">
                <button
                  class="btn btn-info"
                  style={{ marginTop: "14%" }}
                  onClick={handleAddControl}
                >
                  Add Control
                </button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="table-container">
                <div class="table-row table-header">
                  <div>#</div>
                  <div>Control</div>
                  <div>Name</div>
                  <div>Value</div>
                  <div>Remove</div>
                </div>

                {controls.map((control, index) => (
                  <div class="table-row">
                    <div>{index + 1}</div>

                    <div>
                      {control.type == "toggleButton" && (
                        <div class="form-check form-switch form-check-lg mx-3 mb-0">
                          <input
                            id={"toggleButton" + (index + 1)}
                            class="form-check-input mt-0"
                            type="checkbox"
                            name={control.name}
                            onChange={(e) => testControl(control, e, index)}
                          ></input>
                        </div>
                      )}

                      {control.type == "button" && (
                        <button
                          id={"button" + (index + 1)}
                          class="btn btn-primary"
                          onClick={(e) => testControl(control, e, index)}
                        >
                          {control.name}
                        </button>
                      )}

                      {control.type == "messageButton" && (
                        <button
                          id={"button" + (index + 1)}
                          class="btn btn-primary"
                          onClick={(e) => testControl(control, e, index)}
                        >
                          {control.name}
                        </button>
                      )}
                    </div>

                    <div>
                      {/* {control.type == "button" && ( */}
                      <div>
                        <input
                          id={"txt_btn_name" + (index + 1)}
                          placeholder="Enter Name"
                          class="input"
                          disabled={editRow != "txt_btn_name" + (index + 1)}
                          value={control.name}
                          onChange={(e) =>
                            handleInputChange("name", control.type, e, index)
                          }
                        ></input>
                        {editRow != "txt_btn_name" + (index + 1) && (
                          <span
                            class="edit-btn"
                            onClick={() =>
                              editControl("editname", control.type, index + 1)
                            }
                          >
                            Edit
                          </span>
                        )}
                        {editRow == "txt_btn_name" + (index + 1) && (
                          <div>
                            <span
                              class="edit-btn"
                              onClick={() =>
                                editControl(
                                  "cancelname",
                                  control.type,
                                  index + 1
                                )
                              }
                            >
                              <span class="btn btn-sm btn-danger-soft px-2 mb-0">
                                <i class="bi bi-slash-circle fa-fw"></i>
                              </span>
                            </span>
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </div>

                    <div>
                      {/* value start */}
                      {/* {control.type == "button" && ( */}
                      <div>
                        <input
                          id={"txt_btn_value" + (index + 1)}
                          placeholder="Enter value"
                          class="input"
                          disabled={editRow != "txt_btn_value" + (index + 1)}
                          value={control.value}
                          onChange={(e) =>
                            handleInputChange("value", control.type, e, index)
                          }
                        ></input>
                        {editRow != "txt_btn_value" + (index + 1) && (
                          <span
                            class="edit-btn"
                            onClick={() =>
                              editControl("editvalue", control.type, index + 1)
                            }
                          >
                            Edit
                          </span>
                        )}
                        {editRow == "txt_btn_value" + (index + 1) && (
                          <div>
                            <span
                              class="edit-btn"
                              onClick={() =>
                                editControl(
                                  "cancelvalue",
                                  control.type,
                                  index + 1
                                )
                              }
                            >
                              <span class="btn btn-sm btn-danger-soft px-2 mb-0">
                                <i class="bi bi-slash-circle fa-fw"></i>
                              </span>
                            </span>
                          </div>
                        )}
                        {/* value end */}
                      </div>
                      {/* )} */}
                    </div>

                    {/* remove */}
                    <div>
                      <button
                        class="btn btn-danger"
                        onClick={() => removeRow(index)}
                      >
                        Remove
                      </button>
                    </div>
                    {/* remove end */}
                  </div>
                ))}
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <button
                  class="btn btn-primary"
                  style={{ marginTop: "14%" }}
                  onClick={handleSubmit}
                >
                  Save All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IoTSetting;
