import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Profile from './Admin/Profile';
import Dashboard from './Admin/Dashboard';
import EditProfile from './Admin/EditProfile';
import BookingAd from './Admin/BookingAd';
import Login from './Admin/Login';
import Register from './Admin/Register';
import {store} from './store';
import { Provider } from 'react-redux';
import Main from './Home/main';
import Home from './Home/home';
import SponsorDetail from './Home/sponsorDetail';
import About from './Home/about';
import Contact from './Home/contact';
import LeadDetail from './Admin/leadDetail';
import Search from './Home/searchResult';
import IoTSetting from './Admin/IoTSetting';
import IoT from './Admin/IoT';
import VideoStream from './Admin/VideoStream';
import PrivacyPolicy from './Home/privacyPolicy';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>userDetail
    <Provider store={store}>
       <BrowserRouter>
       <Routes>
       <Route path='/Login'  element={<Login />} />   
       <Route path='/Register'  element={<Register />} />   
       <Route path="/" element={<Main />}>   
             <Route path='/'  element={<Home />} /> 
             <Route path='/Search/:category/:searchText' element={<Search />} /> 
             <Route path='/Detail/:id' element={<SponsorDetail />} /> 
             <Route path='/About'  element={<About />} /> 
             <Route path='/Contact'  element={<Contact />} /> 
             <Route path='/PrivacyPolicy'  element={<PrivacyPolicy />} /> 
          <Route path='/Admin/Dashboard'  element={<Dashboard />} /> 
          <Route path='/Admin/LeadDetail/:id'  element={<LeadDetail />} /> 
          <Route path='/Admin/EditProfile'  element={<EditProfile />} /> 
          <Route path='/Admin/BookingAd'  element={<BookingAd />} /> 
          <Route path='/Admin/Profile'  element={<Profile />} />  
          <Route path='/Admin/IoT'  element={<IoT />} />  
          <Route path='/Admin/IoTSetting'  element={<IoTSetting />} />  
          <Route path='/Admin/VideoStream'  element={<VideoStream />} /> 
        </Route>      
       
        {/* <Route path="/Admin" element={<App />}>             
          <Route path='/Admin/Dashboard'  element={<Dashboard />} /> 
          <Route path='/Admin/LeadDetail/:id'  element={<LeadDetail />} /> 
          <Route path='/Admin/EditProfile'  element={<EditProfile />} /> 
          <Route path='/Admin/BookingAd'  element={<BookingAd />} /> 
          <Route path='/Admin/Profile'  element={<Profile />} />        
        </Route> */}
          
      </Routes>
      </BrowserRouter>
      </Provider>
    // </React.StrictMode>
  );  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();