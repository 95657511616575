export const ProfileModel={
    id:0,
    firstName: '',
    lastName: '',
    emailId: '',
    contactNumber:'',
    profileImageUrl:'',
    isValid:true
}

export const BusinessProfileModel={
    id:0,
    businessTypeList:[],
    businessTypeId: '-1',
    registeredName: '',
    emailId: '',
    mobileNo:'',
    landLine:'',
    contactPerson:'',
    contactPersonMobile:'',
    businessDescription:'',
    gstin:'',
    businessCoverImage:'',
    businessLogo:'',
    isValid:true
}

export const BusinessDetailVM={
    id:0,
    registeredName: '',
    emailId: '',
    mobileNo:'',
    landLine:'',
    contactPerson:'',
    contactPersonMobile:'',
    businessDescription:'',
    gstin:'',
    businessCoverImage:'',
    businessLogo:'',
    addressDetail:'',
    landmark:'',
    services:[]
}

export const AddressModel={
    id:0,
    stateList:[],
    cityList:[],
    address: '',
    city: '',
    state: '',
    zipCode:'',
    landmark:'',
    isValid:true
}