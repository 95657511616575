export const LeadDTO={
    id:0,
    targetBusinessName:'',
    targetBusiness:0,
    serviceName:'',
    name: '',
    description: '',
    contactNumber: '',
    emailAddress:'',
    services:[],
    serviceType:0,
    duration:0,
    amountPaid:0,
    serviceRequestTime:null,
    serviceClosedTime:null,
    lastComment:'',
    statusId:'0',
    isValid:true
}

export const LeadStatusCount={
    newStatusId:0,
    newStatusCount:0,
    inProgressStatusId:0,
    inProgressStatusCount:0,
    holdStatusId:0,
    holdStatusCount:0,
    lostStatusId:0,
    lostStatusCount:0,
    wonStatusId:0,
    wonStatusCount:0,
    deleteStatusId:0,
    deleteStatusCount:0,
}