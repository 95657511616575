export const ServiceDetailModel={
    id:0,
    name: '',
    description: '',
    imagePath: '',
    videoPath:'',
    rate:0,
    isValid:true
}

export const BusinessResourceModel={
    id:0,
    fileUrl: '',
    fileType: '',
    isValid:true
}

export const ProductModel={
    id:0,
    name: '',
    description: '',
    isValid:true
}

export const ProductImageModel={
    id:0,
    imageUrl: '',
    uploadFor: '',
    isValid:true
}