import { React, memo, useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
// import {toggleLoaderVisible} from '../../commonSlice';
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import {CommonModel} from '../Models/commonModel';
import {checkValidation,showSaveLoader,showListLoader,getBusinessTypes,getImageUrl} from '../common'
import {ProfileModel,BusinessProfileModel,AddressModel} from "../Models/Basic-Detail";
import {GetValidationMessage,ProfileValidateColumn,BusinessProfileValidateColumn,AddressValidateColumn} from '../Models/validationFields';
import { SaveUserProfile,GetUserDetailbyId,SaveBusinessDetail,GetStates,GetCities, GetUserBusinessDetail, GetAddressDetail,
         SaveAddressDetail } from "../Services/Service";
// import SignalRService from '../SignalRService '

function EditProfile() {
  const [editProfile, setProfile] = useState(ProfileModel);
  const profileValidation = useRef(ProfileValidateColumn);
  const businessProfileValidation = useRef(BusinessProfileValidateColumn);
  const addressValidation = useRef(AddressValidateColumn);
  const [businessProfile, setBusinessProfile] = useState(BusinessProfileModel);
  const [addressProfile, setAddressProfile] = useState(AddressModel);
  const [showLoader, setShowLoader] = useState(CommonModel);
  const activeTab = useRef('');
  const uploadedImage = useRef();
  const uploadedLogoImage = useRef();
  const stateList=useRef([]);
  const cityList=useRef([]);

  // const dispatch= useDispatch();

  // // signalR setting
  //   const [messages, setMessages] = useState([]);
  //   const [user, setUser] = useState('');
  //   const [message, setMessage] = useState('');
 

  useEffect(() => {
    getUserDetail();

    // SignalRService.startConnection();

    // SignalRService.addMessageListener((receivedUser, receivedMessage) => {
    //     const newMessage = `${receivedUser}: ${receivedMessage}`;
    //     setMessages([...messages, newMessage]);
    // });

    // return () => {
    //     SignalRService.stopConnection();
    // };
  }, []);

  const onFileChange=(event)=> {
    const files = event.target.files;
    if (files.length === 0)
      return;
    const reader = new FileReader();
    uploadedImage.current = files;
    
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if(activeTab.current=='BasicTab'){
       var profileImageUrl = reader.result;
       setProfile((prevUserData) => ({
        ...prevUserData,
        ['profileImageUrl']: profileImageUrl,
      }));
    }
    else if(activeTab.current=='BusinessDetailTab'){
      var coverImageUrl = reader.result;
      setBusinessProfile((prevUserData) => ({
       ...prevUserData,
       ['businessCoverImage']: coverImageUrl,
     }));
    }
      }
    }

    const onBusinessLogoChange=(event)=> {
      const files = event.target.files;
      if (files.length === 0)
        return;
      const reader = new FileReader();
      uploadedLogoImage.current = files;
      
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
         var logoImageUrl = reader.result;
         setBusinessProfile((prevUserData) => ({
          ...prevUserData,
          ['businessLogo']: logoImageUrl,
        }));
        }
      }

    const getStates= async()=>{
        showListLoader(setShowLoader,true);
        var response = await GetStates();
        if (response!=null && response.status == 200 && response.data != null) { 
          showListLoader(setShowLoader,false);
          stateList.current=response.data;
        }
        else
        showListLoader(setShowLoader,false);
      }

      const getCities= async(stateId)=>{
          showListLoader(setShowLoader,true);
          var response = await GetCities(stateId);
          if (response!=null && response.status == 200 && response.data != null) { 
            showListLoader(setShowLoader,false);
            cityList.current=response.data;
          }
          else
          showListLoader(setShowLoader,false);
        }

 const getUserDetail= async()=>{
  activeTab.current='';
  showListLoader(setShowLoader,true);
    var response = await GetUserDetailbyId();
    if (response!=null && response.status == 200 && response.data != null) { 
      showListLoader(setShowLoader,false);
      setProfile(() => ({
        ['firstName']: response.data.firstName,
        ['lastName']: response.data.lastName,
        ['emailId']: response.data.emailId,
        ['contactNumber']: response.data.contactNumber,
        ['profileImageUrl']: response.data.profileImage
      }));
    }
    else
    showListLoader(setShowLoader,false);
  }

  const handleTabClick= (tabName)=>{
    if(tabName==='BusinessDetailTab')
    {
      activeTab.current=tabName;
      if(businessProfile.businessTypeList.length==0)
        getBusinessTypes(setBusinessProfile,false,setShowLoader);
        getUserBusinessDetail();
    }
    else if(tabName==='AddressTab')
    {
      activeTab.current=tabName;
      if(stateList.current.length==0)
       getStates();
      if(addressProfile.address==='')
      getAddressDetail();
    }
    else if(tabName==='BasicTab')
        activeTab.current=tabName;
  }

  const getUserBusinessDetail= async()=>{    
    showListLoader(setShowLoader,true);
      var response = await GetUserBusinessDetail();
      if (response!=null && response.status == 200 && response.data != null) { 
         showListLoader(setShowLoader,false);
        setBusinessProfile((prevData) => ({
          ...prevData,
          ['id']: response.data.id,
          ['businessTypeId']: response.data.businessTypeId,
          ['registeredName']: response.data.registeredName,
          ['emailId']: response.data.emailId,
          ['mobileNo']: response.data.mobileNo,
          ['landLine']: response.data.landLine,
          ['contactPerson']: response.data.contactPerson,
          ['contactPersonMobile']: response.data.contactPersonMobile,
          ['businessDescription']: response.data.businessDescription,
          ['gstin']: response.data.gstin,
          ['businessCoverImage']: response.data.businessCoverImage,
          ['businessLogo']: response.data.businessLogo
        }));
      }
      else
      showListLoader(setShowLoader,false);      
    }

    
  const getAddressDetail= async()=>{   
    showListLoader(setShowLoader,true); 
      var response = await GetAddressDetail();
      if (response!=null && response.status == 200 && response.data != null) { 
        showListLoader(setShowLoader,false);     
        getCities(response.data.state); 
        setAddressProfile((prevData) => ({
          ...prevData,
          ['address']: response.data.address,
          ['city']: response.data.city,
          ['state']: response.data.state,
          ['zip']: response.data.zip,
          ['landmark']: response.data.landmark
        }));       
      }
      else
      showListLoader(setShowLoader,false);
    }

  const basicProfileEdit = (e) => {
    const { name, value } = e.target;
    setProfile((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    const validationModel=[];
		checkValidation(e.target.name,e.target.value,validationModel)
		profileValidation.current.map((k)=>{
			var model=	validationModel.find((key)=>key.fieldName==k.fieldName);
			if(model!=undefined && model!=null)
			{
				k.isSuccess=model.isSuccess;
				k.errorMessage=model.errorMessage;
			}			
		})	
  };

  const businessProfileEdit = (e) => {
    const { name, value } = e.target;
    setBusinessProfile((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    const validationModel=[];
		checkValidation(e.target.name,e.target.value,validationModel)
		businessProfileValidation.current.map((k)=>{
			var model=	validationModel.find((key)=>key.fieldName==k.fieldName);
			if(model!=undefined && model!=null)
			{
				k.isSuccess=model.isSuccess;
				k.errorMessage=model.errorMessage;
			}			
		})	
  };

  const addressProfileEdit = (e) => {
    const { name, value } = e.target;
    setAddressProfile((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    const validationModel=[];
		checkValidation(e.target.name,e.target.value,validationModel)
		addressValidation.current.map((k)=>{
			var model=	validationModel.find((key)=>key.fieldName==k.fieldName);
			if(model!=undefined && model!=null)
			{
				k.isSuccess=model.isSuccess;
				k.errorMessage=model.errorMessage;
			}			
		})	

    if(e.target.name==='state')
         getCities(e.target.value);
  };

  const basicInfoSubmit =async (event) => {
    event.preventDefault();   
    let isFormValid=true;
    profileValidation.current.filter((key)=>{
      if(key.isSuccess==false)
        return isFormValid=false;			  
    });		
    if(isFormValid)
    {
      showSaveLoader(setShowLoader,true);
    var response = await SaveUserProfile(uploadedImage.current,JSON.stringify(editProfile));
    if (response!=null && response.status == 200 && response.data != null) {
      showSaveLoader(setShowLoader,false); 
      uploadedImage.current=null;
      toast.success('Changes saved successfully!', {
        position: toast.POSITION.BOTTOM_RIGHT
    });
    }
    else{
      showSaveLoader(setShowLoader,false);
      toast.error('Something went wrong!', {
        position: toast.POSITION.BOTTOM_RIGHT
    });
  }
}
else{
  setProfile((prevUserData) => ({
    ...prevUserData,
    ['isValid']: isFormValid		 			
    }));
}
  };

  const businessInfoSubmit = async (event) => {
    event.preventDefault();
    let isFormValid=true;
    businessProfileValidation.current.filter((key)=>{
      if(key.isSuccess==false)
        return isFormValid=false;			  
    });		
    if(isFormValid)
    {
    showSaveLoader(setShowLoader,true);
    var response = await SaveBusinessDetail(uploadedLogoImage.current,uploadedImage.current,JSON.stringify(businessProfile));
    if (response!=null && response.status == 200 && response.data != null) {   
      showSaveLoader(setShowLoader,false); 
      toast.success(response.data.response_text, {
        position: toast.POSITION.BOTTOM_RIGHT
    });
    }
    else{ 
      showSaveLoader(setShowLoader,false);
      toast.error('Something went wrong!', {
        position: toast.POSITION.BOTTOM_RIGHT
    });
  }    
}
else{
  setBusinessProfile((prevUserData) => ({
    ...prevUserData,
    ['isValid']: isFormValid		 			
    }));
}
  };

  const addressInfoSubmit = async (event) => {
    event.preventDefault();
    let isFormValid=true;
    addressValidation.current.filter((key)=>{
      if(key.isSuccess==false)
        return isFormValid=false;			  
    });		
    if(isFormValid)
    {
    showSaveLoader(setShowLoader,true);
    var response = await SaveAddressDetail(addressProfile);
    if (response!=null && response.status == 200 && response.data != null) {
      showSaveLoader(setShowLoader,false);
      toast.success(response.data.response_text, {
        position: toast.POSITION.BOTTOM_RIGHT
    });
    }
    else{
      showSaveLoader(setShowLoader,false);
      toast.error('Something went wrong!', {
        position: toast.POSITION.BOTTOM_RIGHT
    });
  }    
}
else{
  setAddressProfile((prevUserData) => ({
    ...prevUserData,
    ['isValid']: isFormValid		 			
    }));
}
  };

  return (
    <>   
       <div class="container mt-5 vstack gap-4">
    {/* loader  */}
    {showLoader.listLoader==true &&
    <div class="loading">
  <div class="spinner">
  <div class="spinner-border text-primary" role="status">
  <span class="sr-only">Loading...</span>
</div>
  </div>
</div>
}

<div class="row"  style={{background: '#c8e3fa'}}>
				<div class="col-12 mb-4 mb-sm-1">
					<div class="d-sm-flex justify-content-between align-items-center">
						<h4 class="h4 mb-2 mb-sm-0">Edit Profile</h4>
						{/* <div class="d-grid"><a href="#" class="btn btn-primary-soft mb-0"><i class="bi bi-plus-lg fa-fw"></i> New Booking</a></div>				 */}
					</div>
				</div>
			</div>
      {/* <h5 className="page-header">
        Profile <small>add/manage your profile...</small>
      </h5> */}
         <div class="row" style={{background: '#f7f7f7'}}>
          <div class="col-12 mb-4 mb-sm-5">
              {/* <p class="f-s-12">
                Add quick, dynamic tab functionality to transition through panes
                of local content, even via dropdown menus.
              </p> */}
              <ul class="nav nav-tabs nav-bottom-line nav-responsive nav-justified">
							<li class="nav-item"> 
								<a href="#home" onClick={()=>handleTabClick('BasicTab')} class="nav-link mb-0 active" data-bs-toggle="tab"><i class="bi bi-briefcase-fill fa-fw me-1"></i>Profile</a> 
							</li>
							<li class="nav-item">
								<a class="nav-link mb-0" onClick={()=>handleTabClick('BusinessDetailTab')} data-bs-toggle="tab" href="#profile"><i class="bi bi-x-octagon fa-fw me-1"></i>Business Profile</a> 
								</li>
							<li class="nav-item"> 
								<a class="nav-link mb-0" onClick={()=>handleTabClick('AddressTab')} data-bs-toggle="tab" href="#address"><i class="bi bi-patch-check fa-fw me-1"></i>Address</a> 
							</li>
						</ul>

            <div class="tab-content p-2 p-sm-4" id="nav-tabContent">
<div class="tab-pane fade show active" id="home">
<form method="POST"  onSubmit={basicInfoSubmit}>
                  <div className="row row-space-30">
                    <div className="col-xl-6 ">
                      <div className="form-group m-b-10">                      
                        <div className="row row-space-6">                       
                          <div className="col-md-6">
                          <label>
                         First Name <span className="text-danger">*</span>
                          { profileValidation.current[0].isSuccess==false && <span class="text-danger">{profileValidation.current[0].errorMessage}</span>}
                        </label>
                            <input
                              type="text"
                              className="form-control m-b-5"
                              name="firstName"
                              value={editProfile.firstName}
                              onChange={basicProfileEdit}
                              placeholder="First Name"
                            />
                          </div>                        
                          <div className="col-md-6">
                          <label>
                         Last Name <span className="text-danger">*</span>
                          { profileValidation.current[1].isSuccess==false && <span class="text-danger">{profileValidation.current[1].errorMessage}</span>}
                        </label>
                            <input
                              type="text"
                              className="form-control m-b-5"
                              name="lastName"
                              value={editProfile.lastName}
                              onChange={basicProfileEdit}
                              placeholder="Last Name"
                            />
                          </div>
                         
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="emailId"
                          value={editProfile.emailId}
                          onChange={basicProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Upload Profile Image
                        </label>
                        <input
                          type="File"
                          className="form-control"                          
                          name="profileImage"
                          onChange={onFileChange}
                        />
                       { editProfile.profileImageUrl !="" && <img src={editProfile.profileImageUrl} name="profileImageUrl" width="80px" height="80px"></img>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label>
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          name="contactNumber"
                          value={editProfile.contactNumber}
                          onChange={basicProfileEdit}
                        />
                      </div>
                    </div>

                    <div class="form-group">
                    <button type="submit" class="btn btn-primary"  disabled={showLoader.saveLoader}>
               { showLoader.saveLoader==false && <span> Save Changes</span>}
               {showLoader.saveLoader==true &&  <span> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp; Saving...</span>
               }
              </button>
                    </div>
                  </div>
                  </form>
</div>

<div class="tab-pane fade" id="profile">
<form method="POST" onSubmit={businessInfoSubmit}>
                  <div className="row row-space-30">
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label>
                          This 
                          Business Type <span className="text-danger">*</span>
                          { businessProfileValidation.current[0].isSuccess==false && <span class="text-danger">{businessProfileValidation.current[0].errorMessage}</span>}
                        </label>
                        <select class="form-control" name="businessTypeId" value={businessProfile.businessTypeId} onChange={businessProfileEdit}>                          
                        <option  value="-1">--Select Service--</option>
                            {businessProfile.businessTypeList.map((k)=>{
                             return <option  value={k.id}>{k.name}</option>
                            })
                            }										
									</select>                      
                      </div>
                      <div className="form-group">
                        <label>
                          Business Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="emailId"
                          value={businessProfile.emailId}
                          onChange={businessProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>LandLine No. </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="landLine"
                          value={businessProfile.landLine}
                          onChange={businessProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>Contact Person Mobile </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="contactPersonMobile"
                          value={businessProfile.contactPersonMobile}
                          onChange={businessProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Upload Business Logo
                        </label>
                        <input
                          type="File"
                          className="form-control"                          
                          name="businessLogo"
                          onChange={onBusinessLogoChange}
                        />
                        { businessProfile.businessLogo !="" && <img src={getImageUrl(businessProfile.businessLogo)} width="80px" height="80px"></img>}
                      </div>
                      <div className="form-group">
                        <label>
                          Business Description
                          <span className="text-danger">*</span>
                          { businessProfileValidation.current[2].isSuccess==false && <span class="text-danger">{businessProfileValidation.current[2].errorMessage}</span>}
                        </label>
                        <textarea
                          className="form-control"
                          placeholder=""
                          name="businessDescription"
                          value={businessProfile.businessDescription}
                          onChange={businessProfileEdit}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label>
                          Business Name <span className="text-danger">*</span>
                          { businessProfileValidation.current[1].isSuccess==false && <span class="text-danger">{businessProfileValidation.current[1].errorMessage}</span>}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="xyx solutions"
                          name="registeredName"
                          value={businessProfile.registeredName}
                          onChange={businessProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Mobile Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="mobileNo"
                          value={businessProfile.mobileNo}
                          onChange={businessProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>Contact Person </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="contactPerson"
                          value={businessProfile.contactPerson}
                          onChange={businessProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>GSTIN No.</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="gstin"
                          value={businessProfile.gstin}
                          onChange={businessProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Upload Business Cover Image
                        </label>
                        <input
                          type="File"
                          className="form-control"                          
                          name="businessCoverImage"
                          onChange={onFileChange}
                        />
                       { businessProfile.businessCoverImage !="" &&  <img src={getImageUrl(businessProfile.businessCoverImage)} width="80px" height="80px"></img>}
                      </div>
                    </div>
                    <div class="form-group">  
                    <button type="submit" class="btn btn-primary"  disabled={showLoader.saveLoader}>
               { showLoader.saveLoader==false && <span> Save Changes</span>}
               {showLoader.saveLoader==true &&  <span> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp; Saving...</span>
               }
              </button>
              </div>
                  </div>
                  </form>
</div>

<div class="tab-pane fade" id="address">
<form method="POST"  onSubmit={addressInfoSubmit}>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label>
                          Address<span className="text-danger">*</span>
                          { addressValidation.current[0].isSuccess==false && <span class="text-danger">{addressValidation.current[0].errorMessage}</span>}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          value={addressProfile.address}
                          onChange={addressProfileEdit}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          City <span className="text-danger">*</span>
                          { addressValidation.current[2].isSuccess==false && <span class="text-danger">{addressValidation.current[2].errorMessage}</span>}
                        </label>
                        <select
                          type="text"
                          className="form-control"
                          name="city"
                          value={addressProfile.city}
                          onChange={addressProfileEdit}>
                            <option  value="-1">--Select City--</option>
                             {cityList.current.map((k)=>{
                             return <option  value={k.id}>{k.cityName}</option>
                            })
                          }
                          </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Landmark<span className="text-danger">*</span>
                          { addressValidation.current[4].isSuccess==false && <span class="text-danger">{addressValidation.current[4].errorMessage}</span>}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="landmark"
                          value={addressProfile.landmark}
                          onChange={addressProfileEdit}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label>
                          State <span className="text-danger">*</span>
                          { addressValidation.current[1].isSuccess==false && <span class="text-danger">{addressValidation.current[1].errorMessage}</span>}
                        </label>
                        <select
                          type="text"
                          className="form-control" 
                          name="state"
                          value={addressProfile.state}
                          onChange={addressProfileEdit}>
                             <option  value="-1">--Select State--</option>
                             {stateList.current.map((k)=>{
                             return <option  value={k.id}>{k.stateName}</option>
                            })
                            }			
                          </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Zip<span className="text-danger">*</span>
                          { addressValidation.current[3].isSuccess==false && <span class="text-danger">{addressValidation.current[3].errorMessage}</span>}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="zip"
                          value={addressProfile.zip}
                          onChange={addressProfileEdit}
                        />
                      </div>
                    </div>
                    <div class="form-group"> <button type="submit" class="btn btn-primary"  disabled={showLoader.saveLoader}>
               { showLoader.saveLoader==false && <span> Save Changes</span>}
               {showLoader.saveLoader==true &&  <span> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp; Saving...</span>
               }
              </button>
              </div>
                  </div>
                  </form>
</div>

</div>
          </div>
          </div>
          </div>
          </>
  );
}

export default memo(EditProfile);
