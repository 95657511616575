import * as signalR from '@microsoft/signalr';
import GlobalSetting from '../config'
import { getUserKey,getMessageModel } from '../common';
const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl('https://localhost:50774/chatHub')
    .build();
console.log(hubConnection);
hubConnection.serverTimeoutInMilliseconds=300000;
export const startConnection = async () => {
    try {   
        Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
        await hubConnection.start();
        let identifier=getUserKey();
        if(identifier!=null)
         sendMessage(identifier,getMessageModel('AddConnection','',''))
        console.log('SignalR connected');
    } catch (error) {
        //console.error('Error connecting to SignalR:', error);
    }
};

hubConnection.onclose( data => {
    retryConnection();
});

hubConnection.on('error', data => {
    retryConnection();
});

const retryConnection = () => {
    setTimeout(() => {
     // console.log('Retrying SignalR connection...');
      startConnection();
    }, 1000); 
  };

export const addMessageListener = (callback) => {
    hubConnection.on('ReceiveMessage', callback);
};

export const sendMessage = (user, message) => {
    hubConnection.invoke('SendMessage', user, message);
};

export const stopConnection = async () => {
    try {        
        await hubConnection.stop();
        console.log('SignalR stopped');
    } catch (error) {
        console.error('Error disconnecting to SignalR:', error);
    }
};

export const sendOffer = (offer) => {
    hubConnection.invoke('SendOffer', offer);
};

export const receiveOffer = (callback,senderConnectionId) => {
    hubConnection.on('ReceiveOffer', callback,senderConnectionId);
};

export const sendIceCandidate = (message) => {
    hubConnection.invoke('SendIceCandidate', message);
};

export const sendAnswer = (answer,targetConnectionId ) => {
    hubConnection.invoke('SendAnswer', answer, targetConnectionId);
};

export const receiveAnswer = (callback) => {
    hubConnection.on('ReceiveAnswer', callback);
};

export const receiveIceCandidate = (callback) => {
    hubConnection.on('ReceiveIceCandidate', callback);
};



export default {
    startConnection,
    addMessageListener,
    sendMessage,
    stopConnection,
    sendOffer,
    receiveOffer,
    sendIceCandidate,
    sendAnswer,
    receiveAnswer,
    receiveIceCandidate
};
