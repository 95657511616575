import {React,useState,useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
//import {setUserDetail} from '../Slices/userSlice';
import {showSaveLoader,showListLoader,getUserKey,checkUserIdentifier,getBusinessTypes,getImageUrl} from '../common'
import {GetBusinessDetail,GetServices } from "../Services/Service";
import {CommonModel} from '../Models/commonModel';
import {BusinessDetailVM} from "../Models/Basic-Detail";
function SponsorDetail() {
	const { id } = useParams();
	const [businessDetail, setBusinessDetail] = useState(BusinessDetailVM);
    useEffect(() => {
		getBusinessDetail();		
	  }, []);	
	
	  const getBusinessDetail= async()=>{
		var response = await GetBusinessDetail(id);
		if (response!=null && response.status == 200 && response.data != null) { 		       
		  setBusinessDetail(response.data);
		  getServiceList();
		}
	  }     
	  const getServiceList= async()=>{
		var response = await GetServices(id);
		if (response!=null && response.status == 200 && response.data != null) { 		       
			setBusinessDetail((prevUserData) => ({
				...prevUserData,
				['services']: response.data,
			  }));
		}
	  }      

    return ( 
    <>

<div>
	<div className="container-fluid px-0">
    <Carousel  showThumbs={false} showArrows={true} autoPlay={true}    showStatus={false} showIndicators={false}>
			
				<div>
					<div className="w-100 h-100">
						<div className="card rounded-0 overflow-hidden">
							<img src={getImageUrl(businessDetail.businessCoverImage)} style={{maxHeight:"500px"}} className="" alt=""></img>
						</div>
					</div>
				</div>
     </Carousel>
	</div>
</div>

<section>
	<div className="container">
		<div className="row">
			<div className="col-12">
				<div className="card bg-light p-0 pb-0">
					<div className="card-body d-flex justify-content-between flex-wrap">
						<div>
							<div className="badge text-bg-dark"><i className="fa-solid fa-spa fa-fw text-warning"></i> Spa</div>
							<div className="badge text-bg-dark"><i className="bi bi-scissors fa-fw text-warning"></i> Salon</div>
							<div className="badge text-bg-dark"><i className="fas fa-star fa-fw text-warning"></i> 4.5</div>
							
							<h1 className="h3 mt-2 mb-1">{businessDetail.registeredName}</h1>
							<p className="mb-2 mb-sm-0"><i className="bi bi-geo-alt me-1 text-primary"></i>{businessDetail.addressDetail}</p>
							<p className="mb-2 mb-sm-0"><i className="bi bi-geo-alt me-1 text-primary"></i>{businessDetail.landmark}</p>
						</div>
						<ul className="list-inline mb-0">
							<li className="list-inline-item">
								<a href="#" className="btn btn-sm btn-white px-2"><i className="fa-solid fa-fw fa-heart"></i></a>
							</li>
							<li className="list-inline-item dropdown">
								<a href="#" className="btn btn-sm btn-white px-2" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
									<i className="fa-solid fa-fw fa-share-alt"></i>
								</a>

								<ul className="dropdown-menu min-w-auto shadow rounded" aria-labelledby="dropdownShare">
									<li><a className="dropdown-item" href="#"><i className="fab fa-twitter-square me-2"></i>Twitter</a></li>
									<li><a className="dropdown-item" href="#"><i className="fab fa-facebook-square me-2"></i>Facebook</a></li>
									<li><a className="dropdown-item" href="#"><i className="fab fa-linkedin me-2"></i>LinkedIn</a></li>
									<li><a className="dropdown-item" href="#"><i className="fa-solid fa-copy me-2"></i>Copy link</a></li>
								</ul>
							</li>
							<li className="list-inline-item">
								<a href="#" className="btn btn-sm btn-white px-2"><i className="bi bi-exclamation-triangle-fill fa-fw"></i></a>
							</li>
						</ul>
					</div>
					<div className="card-footer bg-transparent border-top py-0">
						<ul className="nav nav-tabs nav-bottom-line nav-responsive border-0" role="tablist">
							<li className="nav-item"> <a className="nav-link mb-0 active" data-bs-toggle="tab" href="#tab-1">About</a> </li>
							<li className="nav-item"> <a className="nav-link mb-0" data-bs-toggle="tab" href="#tab-2">Deals</a> </li>
							<li className="nav-item"> <a className="nav-link mb-0" data-bs-toggle="tab" href="#tab-3">Services</a> </li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className="pt-0">
	<div className="container">
		<div className="tab-content mb-0" id="tour-pills-tabContent">
			<div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
				<div className="row g-4 g-lg-5">
					<div className="col-lg-7 col-xl-8">
						<div className="card bg-transparent mb-4">
							<div className="card-header bg-transparent border-bottom px-0 pt-0">
								<h4 className="mb-0">Description</h4>
							</div>
							<div className="card-body px-0 pb-0">
								<p className="mb-3">{businessDetail.businessDescription}</p>

							</div>
						</div>

						<div class="card bg-transparent" id="room-options">
						<div class="card-header bg-transparent border-bottom px-0 pt-0"><h4 class="mb-0">Services</h4></div>
						<div class="card-body pt-4 p-0">
							<div class="vstack gap-4">
								{ businessDetail.services!=null &&	businessDetail.services.map((k)=>{
								return	<div class="card shadow p-3">
									<div class="row g-4">
										<div class="col-md-5 position-relative">
											{/* <div class="position-absolute top-0 start-0 z-index-1 mt-3 ms-4">
												<div class="badge text-bg-danger">30% Off</div>
											</div> */}

											<div class="tiny-slider overflow-hidden rounded-2">
											<img src={k.imagePath} alt="Card image"></img>
											</div>

											<a href="#" class="btn btn-link text-decoration-underline p-0 mb-0 mt-1" data-bs-toggle="modal" data-bs-target="#roomDetail"><i class="bi bi-eye-fill me-1"></i>View more details</a>
										</div>

										<div class="col-md-7">
											<div class="card-body d-flex flex-column h-100 p-0">
					
												<h5 class="card-title"><a href="#">{k.name}</a></h5>

												<p class="text-success mb-0">{k.description}</p>
											
												<div class="d-sm-flex justify-content-sm-between align-items-center mt-auto">
													<div class="d-flex align-items-center">
														<h5 class="fw-bold mb-0 me-1">{k.rate}</h5>
														<span class="mb-0 me-2">/hour</span>
														{/* <span class="text-decoration-line-through mb-0">$1000</span> */}
													</div>
													<div class="mt-3 mt-sm-0">
														<a href="#" class="btn btn-sm btn-primary mb-0">Book Now</a>    
													</div>                  
												</div>
											</div>
										</div>
									</div>
								</div>
								})
								}
							</div>
						</div>
					</div>
					</div>

					<div className="col-lg-5 col-xl-4">
						<div className="card shadow mb-4">
							<div className="card-header border-bottom">
								<h5 className="mb-0">Get Direction</h5>
							</div>
							<div className="card-body">
								<iframe className="w-100 grayscale rounded" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9663095343008!2d-74.00425878428698!3d40.74076684379132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259bf5c1654f3%3A0xc80f9cfce5383d5d!2sGoogle!5e0!3m2!1sen!2sin!4v1586000412513!5m2!1sen!2sin" height="200" style={{border:"0"}} aria-hidden="false" tabIndex="0"></iframe>
								
								<ul className="list-group list-group-borderless my-3">
									<li className="list-group-item">
										<a href="#" className="h6 fw-normal mb-0">
											<i className="bi fa-fw bi-geo-alt me-2"></i>5855 W Century Blvd, Los Angeles
										</a>
									</li>

									<li className="list-group-item">
										<a href="#" className="h6 fw-normal mb-0">
											<i className="bi fa-fw bi-telephone-forward me-2"></i>+31 123 456 789
										</a>
									</li>
									<li className="list-group-item">
										<a href="#" className="h6 fw-normal mb-0">
											<i className="bi fa-fw bi-envelope me-2"></i>example@gmail.com
										</a>
									</li>
								</ul>
								<ul className="list-inline mb-0 mt-3">
									<li className="list-inline-item">Follow us on: </li>
									<li className="list-inline-item"> <a className="btn btn-sm px-2 bg-facebook mb-0" href="#"><i className="fab fa-fw fa-facebook-f"></i></a> </li>
									<li className="list-inline-item"> <a className="btn btn-sm shadow px-2 bg-instagram mb-0" href="#"><i className="fab fa-fw fa-instagram"></i></a> </li>
									<li className="list-inline-item"> <a className="btn btn-sm shadow px-2 bg-twitter mb-0" href="#"><i className="fab fa-fw fa-twitter"></i></a> </li>
									<li className="list-inline-item"> <a className="btn btn-sm shadow px-2 bg-linkedin mb-0" href="#"><i className="fab fa-fw fa-linkedin-in"></i></a> </li>
								</ul>
							</div>
						</div>

						<div className="card shadow">
							<div className="card-header border-bottom">
								<h5 className="mb-0">Working Hours</h5>
							</div>
							<div className="card-body">
								<ul className="list-group list-group-borderless mb-0">
									<li className="list-group-item d-flex justify-content-between">
										<span>Monday</span>
										<span>Day off</span>
									</li>

									<li className="list-group-item py-0"><hr className="my-1"></hr></li>

									<li className="list-group-item d-flex justify-content-between">
										<span>Tuesday</span>
										<span>9:00 AM to 8:00 PM</span>
									</li>

									<li className="list-group-item py-0"><hr className="my-1"></hr></li>

									<li className="list-group-item d-flex justify-content-between">
										<span>Wednesday</span>
										<span>9:00 AM to 8:00 PM</span>
									</li>									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
				<div className="row g-4">
					<div className="col-lg-8">
						<div className="card card-body border mb-4">
							<div className="d-sm-flex justify-content-between mb-2">
								<div className="mb-2 mb-sm-0">
									<div className="badge text-bg-dark"><i className="bi bi-scissors fa-fw text-warning"></i> Salon</div>
									<div className="badge text-bg-success">40% off</div>
								</div>	
								<span className="text-success">Free Cancellation</span>
							</div>	
							<h5 className="card-title mb-2"><a href="#">Monsoon Offer: Men: Global Hair Color With Free Manicure / Pedicure / Haircut</a></h5>
							<div className="d-flex align-items-center flex-wrap">
								<span className="me-2">Valid for:</span>
								<ul className="nav nav-divider h6 fw-light mb-0">
									<li className="nav-item"><i className="bi bi-person me-1"></i>1 Male</li>
									<li className="nav-item"><i className="bi bi-calendar me-1"></i>Mon - Fri</li>
									<li className="nav-item"><i className="bi bi-clock me-1"></i>11 AM - 6 PM</li>
								</ul>
							</div>

							<div className="d-sm-flex justify-content-between mt-4">
								<div className="hstack gap-1">
									<h5 className="mb-0">$1500</h5>
									<span className="text-decoration-line-through">$1800</span>
								</div>
								<div className="mt-2 mt-sm-0">
									<a href="#" className="btn btn-sm btn-light mb-0" data-bs-toggle="modal" data-bs-target="#detailModal">View Details</a>
									<a href="#" className="btn btn-sm btn-primary mb-0"><i className="bi bi-plus-lg"></i> Add</a>
								</div>	
							</div>
						</div>

						<div className="card card-body border mb-4">
							<div className="d-sm-flex justify-content-between mb-2">
								<div className="mb-2 mb-sm-0">
									<div className="badge text-bg-dark"><i className="fa-solid fa-spa fa-fw text-warning"></i> Spa</div>
									<div className="badge text-bg-success">30% off</div>
								</div>	
								<span className="text-success">Free Cancellation</span>
							</div>	
							<h5 className="card-title mb-2"><a href="#">Women Special Offer (Aroma Therapy - 30 min)</a></h5>
							<div className="d-flex align-items-center flex-wrap">
								<span className="me-2">Valid for:</span>
								<ul className="nav nav-divider h6 fw-light mb-0">
									<li className="nav-item"><i className="bi bi-person me-1"></i>1 Female</li>
									<li className="nav-item"><i className="bi bi-calendar me-1"></i>Mon - Fri</li>
									<li className="nav-item"><i className="bi bi-clock me-1"></i>11 AM - 6 PM</li>
								</ul>
							</div>
							<div className="d-sm-flex justify-content-between mt-4">
								<div className="hstack gap-1">
									<h5 className="mb-0">$820</h5>
									<span className="text-decoration-line-through">$900</span>
								</div>
								<div className="mt-2 mt-sm-0">
									<a href="#" className="btn btn-sm btn-light mb-0" data-bs-toggle="modal" data-bs-target="#detailModal">View Details</a>
									<a href="#" className="btn btn-sm btn-primary mb-0"><i className="bi bi-plus-lg"></i> Add</a>
								</div>	
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="card shadow mb-4">
							<div className="card-header border-bottom">
								<h5 className="mb-0">Your Order</h5>
							</div>
							<div className="card-body">
								<ul className="list-group list-group-borderless mb-3">
									<li className="list-group-item d-flex justify-content-between py-2">
										<span className="h6 fw-light mb-0">Monsoon Offer</span>
										<span className="h6 fw-light mb-0">$260 (x1)</span>
									</li>
									<li className="list-group-item d-flex justify-content-between py-2">
										<span className="h6 fw-light mb-0">Monsoon Offer:global</span>
										<span className="h6 fw-light mb-0">$50</span>
									</li>
									<li className="list-group-item py-2"><hr className="my-0"></hr></li> 
									<li className="list-group-item d-flex justify-content-between pb-0">
										<div>
											<h5 className="fw-normal mb-0">Total</h5>
											<small>Inc. of all taxes</small>
										</div>
										<span className="h5 fw-normal mb-0">$310</span>
									</li>
								</ul>
								<a href="#" className="btn btn-primary-soft w-100 mb-0">Buy now</a>
							</div>
						</div>
						<div className="card card-body shadow">
							<div className="cardt-title">
								<h5>Save $70 instant of code</h5>
							</div>

							<ul className="list-group list-group-borderless mb-0">
								<li className="list-group-item small pb-0"><i className="bi bi-patch-check-fill text-success me-1"></i>Valid on all prepaid deals</li>
								<li className="list-group-item small pb-0"><i className="bi bi-patch-check-fill text-success me-1"></i>No minimum purchase</li>
							</ul>

							<div className="input-group mt-3">
								<input className="form-control form-control border-dashed" placeholder="Coupon code" value="BOOK56"></input>
								<button type="button" className="btn btn-primary">Copy</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
				<h5 className="mb-3"><i className="fa-solid fa-spa fa-fw text-warning"></i> Spa Services</h5>
				<div className="row">
					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Foot Treatments </a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Body Massage </a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Our Special Massage</a>
							</li>
						</ul>
					</div>

					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Head & Foot Treatments </a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Body Spa - Scrubs</a>
							</li>
						</ul>
					</div>

					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Body DE-Tanning</a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Body Polishing </a>
							</li>
						</ul>
					</div>

					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Back Treatments </a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Manicure & Pedicure</a>
							</li>
						</ul>
					</div>
				</div>
				<h5 className="mb-3 mt-5"><i className="bi bi-scissors fa-fw text-warning"></i> Salon Services</h5>
				<div className="row">
					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Threading</a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Bleach</a>
							</li>
						</ul>
					</div>

					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Waxing</a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Blow Dry</a>
							</li>
						</ul>
					</div>

					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Hair Colour</a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Facial & Cleanup</a>
							</li>
						</ul>
					</div>

					<div className="col-sm-6 col-lg-4 col-xl-3">
						<ul className="list-group list-group-borderless mb-0">
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Pre Bridal</a>
							</li>
							<li className="list-group-item">
								<a href="#" className="h6 fw-normal mb-0"> <i className="bi fa-fw bi-arrow-right me-1"></i>Bridal Makeup</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>	
	</div>
</section>

</>
    );
}

export default SponsorDetail;