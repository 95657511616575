import axios from "axios";
import { getUserKey } from "../common";
import GlobalSetting from '../config'
 axios.defaults.baseURL= GlobalSetting.BASE_API_URL;

//  export const setAxiosHeader=()=>{
//   debugger
// axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
//  }


 export const checkLogin = async (loginModel) => {
    try {
      const response = await axios.post("api/login/CheckLogin",loginModel);
      return response;
    } catch (error) {
      return null;
  };
};

export const SearchBusiness = async (searchModel) => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.post("api/DashboardSearch/SearchBusiness",searchModel);    
    return response;
  } catch (error) {      
    return null;
};
};

export const GetLeadCount = async () => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/DashboardSearch/LeadStatusCount");   
    return response;
  } catch (error) {      
    return null;
};
};
export const GetUserLead = async (pages) => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.post("api/Lead/GetUserLead",pages);   
    return response;
  } catch (error) {      
    return null;
};
};

export const GetAllNotification = async (from,to) => {
  try {
    axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/DashboardSearch/GetAllNotification/"+from+'/'+to);   
    return response;
  } catch (error) {      
    return null;
};
};

export const GetStates = async () => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/DashboardSearch/GetStates"); 
    return response;
  } catch (error) {      
    return null;
};
};

export const GetCities = async (stateId) => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/DashboardSearch/GetCities?stateId="+stateId);     
    return response;
  } catch (error) {      
    return null;
};
};

export const addNewUser = async (registerModel) => {
     try {
       const response = await axios.post("api/user/InsertUser",registerModel);
       return response;
     } catch (error) {
       return null;
   };
 };

 export const SaveUserProfile=async (files, userModel)=> {
  try {
  const formData = new FormData();
    // for (var index in files) {
    //   formData.append("file" + index, files[index]);
    // }
  if(files!=null)
  formData.append("ProfileImage" , files[0]);
  formData.append("UserProfile", userModel);
  axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
  const response = await axios.post("api/User/UpdateUserDetail/", formData);
  return response;
  } catch (error) {
    return null;
};
}

export const GetUserDetailbyId = async () => {
     try {
      axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
       const response = await axios.get("api/User/GetUserDetailbyId");     
       return response;
     } catch (error) {      
       return null;
   };
 };

 export const GetBusinessTypes = async () => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/Business/GetBusinessTypes");  
    return response;
  } catch (error) {      
    return null;
};
};


export const GetBusinessDetail = async (id) => {
  try {
    const response = await axios.get("api/Business/GetBusinessDetail/"+id);    
    return response;
  } catch (error) {      
    return null;
};
};

export const GetUserBusinessDetail = async () => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/Business/GetUserBusinessDetail");    
    return response;
  } catch (error) {      
    return null;
};
};

export const GetBusinessDetailById = async (Id) => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/Business/GetBusinessDetailById/"+Id);    
    return response;
  } catch (error) {      
    return null;
};
};

export const GetBusinessDetailList = async () => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/DashboardSearch/GetBusinessDetailList");    
    return response;
  } catch (error) {      
    return null;
};
};

 export const SaveBusinessDetail=async (logoImage,coverImage,businessDetail)=> {
try {
  const formData = new FormData();
  if(logoImage!=null)
  formData.append("LogoImage" , logoImage[0]);
  if(coverImage!=null)
  formData.append("CoverImage" , coverImage[0]);
  formData.append("BusinessDetail", businessDetail);
  axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
  const response = await axios.post("api/Business/SaveBusinessDetail/", formData);
  return response;
  } catch (error) {
    return null;
};
}

export const GetAddressDetail = async () => {
  try {
   axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.get("api/User/GetAddressDetail");     
    return response;
  } catch (error) {      
    return null;
};
};

export const SaveAddressDetail=async (addressDetail)=> {
  try {
    axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.post("api/User/SaveAddressDetail/", addressDetail);
    return response;
    } catch (error) {
      return null;
  };
  }


  export const SaveServiceDetail=async (imageFiles,videoFile, serviceDetailModel)=> {
    try {
    const formData = new FormData();
      // for (var index in files) {
      //   formData.append("file" + index, files[index]);
      // }
    if(imageFiles!=null)
    formData.append("ServiceImageFile" , imageFiles[0]);
    if(videoFile!=null)
    formData.append("ServiceVideoFile" , videoFile[0]);
    formData.append("ServiceDetail", serviceDetailModel);
    axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.post("api/Business/SaveServiceDetails/", formData);
    return response;
    } catch (error) {
      return null;
  };
  }

  export const GetServices = async (id) => {
    try {
      const response = await axios.get("api/Business/GetServices/"+id);   
      return response;
    } catch (error) {      
      return null;
  };
  };

  export const GetUserServiceDetails = async () => {
    try {
     axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
      const response = await axios.get("api/Business/GetUserServiceDetails");   
      return response;
    } catch (error) {      
      return null;
  };
  };
  
export const RemoveServiceDetail=async (serviceId)=> {
  try {
    axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.post("api/Business/RemoveServiceDetail/"+serviceId);
    return response;
    } catch (error) {
      return null;
  };
  }

  export const SaveProductDetail=async (imageFiles,videoFile, productDetailModel)=> {
    try {
    const formData = new FormData();
      for (var index in imageFiles) {
        formData.append("imageFile" + index, imageFiles[index]);
      }

    if(videoFile!=null)
    formData.append("ProductVideoFile" , videoFile[0]);
    formData.append("ProductDetail", productDetailModel);
    axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
    const response = await axios.post("api/Business/SaveProductDetail/", formData);
    return response;
    } catch (error) {
      return null;
  };
  }

    export const GetProductDetails = async () => {
    try {
     axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
      const response = await axios.get("api/Business/GetProductDetails");   
      return response;
    } catch (error) {      
      return null;
  };
  };

  export const RemoveProductDetail=async (productId)=> {
    try {
      axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
      const response = await axios.post("api/Business/RemoveProductDetail/"+productId);
      return response;
      } catch (error) {
        return null;
    };
    }

    export const SaveLeadDetail=async (leadDetail)=> {
      try {
        leadDetail.services=null;
        axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
        const response = await axios.post("api/Lead/CreateLead/", leadDetail);
        return response;
        } catch (error) {
          return null;
      };
      }

      export const GetDevices = async () => {
        try {
         axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
          const response = await axios.get("api/IoT/GetDevices");   
          return response;
        } catch (error) {      
          return null;
      };
      };

      export const GetIoTSetting = async (deviceId) => {
        try {
         axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
          const response = await axios.get("api/IoT/GetIoTSetting?deviceId="+deviceId);   
          return response;
        } catch (error) {      
          return null;
      };
      };

    export const SaveIoTSetting = async (payload) => {
        try {
          axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
          const response = await axios.post("api/IoT/SaveIoTSetting",payload);
          return response;
        } catch (error) {
          return null;
      };
    };

    export const UpdateIoTControl = async (payload) => {
      try {
        axios.defaults.headers["Authorization"]=  "Bearer " + getUserKey();
        const response = await axios.post("api/IoT/UpdateIoTControl",payload);
        return response;
      } catch (error) {
        return null;
    };
  };
  
