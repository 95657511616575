import { React } from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <section style={{ paddingTop: "3.5rem" }} className="bgsection"></section>
      <section className="pt-0 pt-lg-5">
        <div className="container">
          <div className="row g-4">            
            <div class="card">
                <div class="card-header">
                <h3>About Us</h3>
                </div>
              <div class="card-body">
                <p style={{ lineHeight: 2 }}>
                  <strong class="font-medium-3">DigitalAppZone</strong> is a
                  social platform where people can connect, communicate, chat,
                  share files with other people across the world. It has
                  features like file manager, contact and lead management,
                  events/bookings management, freelancing, post
                  jobs/work/products/services etc., profile sharing etc.
                  <br></br> Our aim is to bring all the services under one roof
                  so as to serve user everything at one place.
                </p>
                <h4 class="mb-2">Services provided by us:</h4>
                <section class="kb-content">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-md-4 col-sm-6 kb-search-content">
                          <div class="card border">
                            <div class="card-body text-center">
                              <a routerLink="/social-info">
                                <div class="mb-1 font-large-2">
                                  <i class="fas fa-comments"></i>
                                </div>
                                <h5>Social Networking</h5>
                                <p class="text-muted">
                                  Allow us to chat, communicate with all over
                                  the world.
                                  <br></br> Connects people across the world
                                  <br></br> &nbsp;
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 kb-search-content">
                          <div class="card border">
                            <div class="card-body text-center">
                              <a routerLink="/profileshare-info">
                                <div class="mb-1 font-large-2">
                                  <i class="fal fa fa-user"></i>
                                </div>
                                <h5>Profile Sharing</h5>
                                <p class="text-muted">
                                  Allow us to share profile with all over world
                                  for lead generation, booking, entertainment,
                                  file sharing, webpage purpose etc.
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 kb-search-content">
                          <div class="card border">
                            <div class="card-body text-center">
                              <a routerLink="/post-info">
                                <div class="mb-1 font-large-2">
                                  <i class="far fa fa-comment"></i>
                                </div>
                                <h5>Post</h5>
                                <p class="text-muted">
                                  Allow user to add Post for Advertisement,
                                  Sell, Buy, Job Searching, Hiring Candidate,
                                  Work Searching, Hiring People for Work etc.{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 kb-search-content">
                          <div class="card border">
                            <div class="card-body text-center">
                              <a routerLink="/filemanager-info">
                                <div class="mb-1 font-large-2">
                                  <i class="far fa-file-alt"></i>
                                </div>
                                <h5>File Manager</h5>
                                <p class="text-muted">
                                  Allow us to manage file/folders with
                                  download,share,embed options etc.
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 kb-search-content">
                          <div class="card border">
                            <div class="card-body text-center">
                              <a routerLink="/event-info">
                                <div class="mb-1 font-large-2">
                                  <i class="far fa fa-bolt"></i>
                                </div>
                                <h5>Booking/Event Management</h5>
                                <p class="text-muted">
                                  Allow us to create bookings, events etc.
                                  <br></br> &nbsp;
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 kb-search-content">
                          <div class="card border">
                            <div class="card-body text-center">
                              <a routerLink="/lead-info">
                                <div class="mb-1 font-large-2">
                                  <i class="fas fa-blender-phone"></i>
                                </div>
                                <h5>Lead Management</h5>
                                <p class="text-muted">
                                  Allow us to create/manage lead's generated by
                                  Profile Sharing
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-6 kb-search-content">
                          <div class="card border">
                            <div class="card-body text-center">
                              <a routerLink="/contact-info">
                                <div class="mb-1 font-large-2">
                                  <i class="fas fa-address-book"></i>
                                </div>
                                <h5>Contact Management</h5>
                                <p class="text-muted">
                                  Allow us save contact's for communication,
                                  backup purpose etc.
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
