import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {React} from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

function ServiceDetailModal(props){
 return (  
    <>
    <Modal show={props.showModal} onHide={props.handleClose}>
        <Modal.Header closeButton>
		<h5 class="modal-title">Service Booking</h5>
        </Modal.Header>
        <Modal.Body><div class="card bg-transparent">
					<div class="card-header bg-transparent">
				<h6 class="card-title mb-0"> {props.leadDetail.targetBusinessName}</h6>
					</div>

					<div class="card-body pt-0">
						<div>			
						<div class="mb-3">
								<label class="form-label">Select Service</label>
								<select class="form-control" name="serviceType" value={props.leadDetail.serviceType} onChange={props.leadDetailEdit}>
									<option key="-1">--Select Service--</option>
									{ props.leadDetail.services!=null &&	props.leadDetail.services.map((k)=>{
										return <option value={k.id}>{k.name}</option>
									})
								}
								</select>
							</div>			
							<div class="mb-3">
								<label class="form-label">Name</label>
								<input type="text" class="form-control" placeholder="Enter Your name"
								name="name" value={props.leadDetail.name} onChange={props.leadDetailEdit}></input>
							</div>
						
							<div class="mb-3">
								<label class="form-label">Phone number</label>
								<input type="text" class="form-control" placeholder="Enter Your phone number"
								name="contactNumber" value={props.leadDetail.contactNumber} onChange={props.leadDetailEdit}></input>
							</div>
							
							<div class="mb-3 ms-2">
						      	<label class="form-label">Service Request Datetime</label>
								<br></br>
							    <DateTimePicker is24Hour onChange={props.onDateChange} calendarIcon={null} showLeadingZeros={true} disableClock={true} format="dd/MM/y h:mm a" value={props.dateValue} />
							</div>
							<div class="mb-3">
								<label class="form-label">Duration</label>
								<input type="text" class="form-control" placeholder="Enter Your phone number"
								name="duration" value={props.leadDetail.duration} onChange={props.leadDetailEdit}></input>
							</div>
							<div class="mb-3">
								<label class="form-label">Amount</label>
								<input type="text" class="form-control" placeholder="Enter Your phone number"
								name="amountPaid" value={props.leadDetail.amountPaid} onChange={props.leadDetailEdit}></input>
							</div>

							<div class="mb-3">
								<label class="form-label">Remark</label>
								<textarea type="text" class="form-control" placeholder="Enter Your Remark"
								name="description" value={props.leadDetail.description} onChange={props.leadDetailEdit}></textarea>
							</div>
							<div class="d-grid gap-2 d-md-block">
								<button onClick={()=>props.leadDetailSubmit()} class="btn btn-dark mb-0 form-control" type="button">Creating Booking</button>							
							</div>
						</div> 
					</div>
				</div></Modal.Body>
				        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
</>
 )
}

export default ServiceDetailModal;