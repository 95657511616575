import {React,useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import { GetLeadCount} from "../Services/Service";
import {LeadStatusCount} from "../Models/LeadDTO";
function Dashboard(){
    const [leadCount, setLeadCount] = useState(LeadStatusCount);
    useEffect(() => {
        getLeadCount();
      },[]);

      const getLeadCount= async()=>{      
        //showListLoader(setShowLoader,true);
          var response = await GetLeadCount();
          if (response!=null && response.status == 200 && response.data != null) { 
          //  showListLoader(setShowLoader,false);
            var leadStatusCount=response.data;
            var newLeads= leadStatusCount.find(k=> k.statusId==1)??0;
            var inprogressLeads= leadStatusCount.find(k=> k.statusId==2)??0;
            var holdLeads= leadStatusCount.find(k=> k.statusId==3)??0;
            var lostLeads= leadStatusCount.find(k=> k.statusId==4)??0;
            var wonLeads= leadStatusCount.find(k=> k.statusId==5)??0;            
            var deletedLeads= leadStatusCount.find(k=> k.statusId==6)??0;
            setLeadCount((prevUserData) => ({
                ...prevUserData,
                ['newStatusId']: 1,
                ['newStatusCount']: newLeads.leadStatusCount,
                ['inProgressStatusId']: 2,
                ['inProgressStatusCount']: inprogressLeads.leadStatusCount,
                ['holdStatusId']: 3,
                ['holdStatusCount']: holdLeads.leadStatusCount,
                ['lostStatusId']: 4,
                ['lostStatusCount']: lostLeads.leadStatusCount,
                ['wonStatusId']: 5,
                ['wonStatusCount']: wonLeads.leadStatusCount,
                ['deleteStatusId']: 6, 	
                ['deleteStatusCount']: deletedLeads.leadStatusCount		
                }));
          }
        //   else
        //   showListLoader(setShowLoader,false);
        }      

    return ( <>
	<div class="container mt-5 vstack gap-4">
    <div class="row" style={{background: '#c8e3fa'}}>
				<div class="col-12 mb-4 mb-sm-1">
					<div class="d-sm-flex justify-content-between align-items-center">
						<h1 class="h4 mb-2 mb-sm-0">Dashboard</h1>
						<div class="d-grid"><a href="#" class="btn btn-primary-soft mb-0"><i class="bi bi-plus-lg fa-fw"></i> New Booking</a></div>				
					</div>
				</div>
			</div>

			<div class="row g-4 mb-5" style={{background: '#f7f7f7'}}>
				<Link class="col-md-6 col-xxl-3" to={"/Admin/LeadDetail/"+leadCount.newStatusId}>
					<div class="card card-body bg-warning bg-opacity-10 border border-warning border-opacity-25 p-4 h-100">
						<div class="d-flex justify-content-between align-items-center">			
							<div>
								<h4 class="mb-0">{leadCount.newStatusCount}</h4>
								<span class="h6 fw-light mb-0">New Lead</span>
							</div>
							<div class="icon-lg rounded-circle bg-warning text-white mb-0"><i class="fa-solid fa-hotel fa-fw"></i></div>
						</div>
					</div>
				</Link>
				<div class="col-md-6 col-xxl-3">
					<div class="card card-body bg-success bg-opacity-10 border border-success border-opacity-25 p-4 h-100">
						<div class="d-flex justify-content-between align-items-center">
				
							<div>
								<h4 class="mb-0">$836,789</h4>
								<span class="h6 fw-light mb-0">Total Incomes</span>
							</div>
							<div class="icon-lg rounded-circle bg-success text-white mb-0"><i class="fa-solid fa-hand-holding-dollar fa-fw"></i></div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xxl-3">
					<div class="card card-body bg-primary bg-opacity-10 border border-primary border-opacity-25 p-4 h-100">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<h4 class="mb-0">245</h4>
								<span class="h6 fw-light mb-0">Total Rooms</span>
							</div>
							<div class="icon-lg rounded-circle bg-primary text-white mb-0"><i class="fa-solid fa-bed fa-fw"></i></div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xxl-3">
					<div class="card card-body bg-info bg-opacity-10 border border-info border-opacity-25 p-4 h-100">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<h4 class="mb-0">147</h4>
								<span class="h6 fw-light mb-0">Booked Room</span>
							</div>
							<div class="icon-lg rounded-circle bg-info text-white mb-0"><i class="fa-solid fa-building-circle-check fa-fw"></i></div>
						</div>
					</div>
				</div>
			</div>
	</div>
</>)
}
export default Dashboard;