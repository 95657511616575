import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { toast } from "react-toastify";
//import {setUserDetail} from '../Slices/userSlice';
import {
  showSaveLoader,
  showListLoader,
  getUserKey,
  checkUserIdentifier,
  getBusinessTypes,
  getMessageModel,getImageUrl
} from "../common";
import { BusinessProfileModel } from "../Models/Basic-Detail";
import { LeadDTO } from "../Models/LeadDTO";
import {
  SearchBusiness,
  GetServices,
  SaveLeadDetail,
} from "../Services/Service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CommonModel, SRMessageModel, SearchVM } from "../Models/commonModel";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ServiceDetailModal from "../Modals/serviceDetailModal";
import SignalRService from "../Services/SignalRService";
function Search() {
  const { category, searchText } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [businessProfile, setBusinessProfile] = useState(BusinessProfileModel);
  const [leadDetail, setLeadDetail] = useState(LeadDTO);
  const [businessList, setBusinessList] = useState([]);
  const [showLoader, setShowLoader] = useState(CommonModel);
  var currentdate = new Date();
  const [dateValue, onDateChange] = useState(currentdate);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [searchModel, setSearchModel] = useState(SearchVM);
  useEffect(() => {
    setSearchModel((prevUserData) => ({
      ...prevUserData,
      ['searchCategory']: category,
      ['searchText']:((searchText!='nothing')?searchText:'')
    }));
    getBusinessTypes(setBusinessProfile, false);
  }, []);

  useEffect(() => {
    getSearchBusiness();
  }, [searchModel]);


  const getSearchBusiness = async () => {
    if(searchModel.searchText!='' || searchModel.searchCategory!=0)
    {
    var response = await SearchBusiness(searchModel);
    if (response != null && response.status == 200 && response.data != null) {
      setBusinessList(response.data);
    }
  }
  };

  const getServiceList = async (businessId) => {
    var response = await GetServices(businessId);
    if (response != null && response.status == 200 && response.data != null) {
      setLeadDetail((prevUserData) => ({
        ...prevUserData,
        ["services"]: response.data,
      }));
    }
  };

  const searchModelEdit = (e) => {
    const { name, value } = e.target;
    setSearchModel((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const businessProfileEdit = (e) => {
    const { name, value } = e.target;
    setBusinessProfile((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const openBookingModal = (businessId, registeredName) => {
    getServiceList(businessId);
    setLeadDetail((prevUserData) => ({
      ...prevUserData,
      ["targetBusinessName"]: registeredName,
      ["targetBusiness"]: businessId,
    }));
    setShowModal(true);
  };
  const leadDetailEdit = (e) => {
    const { name, value } = e.target;
    setLeadDetail((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const leadDetailSubmit = async () => {
    showSaveLoader(setShowLoader, true);
    leadDetail.serviceRequestTime = dateValue;
    var response = await SaveLeadDetail(leadDetail);
    if (response != null && response.status == 200 && response.data != null) {
      SignalRService.sendMessage(
        "",
        getMessageModel(
          "LeadMessageByCustomer",
          response.data.response_text,
          ""
        )
      );
      showSaveLoader(setShowLoader, false);
      setShowModal(false);
      toast.success(response.data.response_text, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      showSaveLoader(setShowLoader, false);
      setShowModal(false);
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <ServiceDetailModal
        leadDetail={leadDetail}
        showModal={showModal}
        handleClose={handleClose}
        leadDetailSubmit={leadDetailSubmit}
        leadDetailEdit={leadDetailEdit}
        onDateChange={onDateChange}
        dateValue={dateValue}
      />
      <section class="position-relative py-8 py-lg-8 bgsection">
	<div class="container">
  {/* style={{background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 29%, rgba(5,5,82,1) 100%)'}} */}
		<div class="rounded-3 p-3 p-sm-5" style={{backgroundImage: 'url(../../assets/images/bg/05.jpg)', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
			<div class="row my-2 my-xl-5"> 
				<div class="col-md-8 mx-auto"> 
					<h1 class="text-center text-white">150 Hotels in New York</h1>
				</div>
			</div>
			<form class="bg-mode shadow rounded-3 position-relative p-4 pe-md-5 pb-5 pb-md-4 mb-4">
				<div class="row g-4 align-items-center">
					<div class="col-lg-4">
						<div class="form-control-border form-control-transparent form-fs-md d-flex">
							<i class="bi bi-geo-alt fs-3 me-2 mt-2"></i>
							<div class="flex-grow-1">
								<label class="form-label">Location</label>
                <input
                      name="searchText"
                      value={searchModel.searchText}
                      onChange={searchModelEdit}
                      class="form-control form-control-lg"
                    ></input>
							</div>
						</div>
					</div>

					<div class="col-lg-4">
						<div class="d-flex">
							<i class="bi bi-calendar fs-3 me-2 mt-2"></i>
							<div class="form-control-border form-control-transparent form-fs-md">
								<label class="form-label">Search</label>								
                <select className="form-select js-choice" data-search-enabled="true" name="searchCategory" value={searchModel.searchCategory} onChange={searchModelEdit}>                          
                                   <option  value="-1">--Select Service--</option>
                            {businessProfile.businessTypeList.map((k)=>{
                             return <option  value={k.id}>{k.name}</option>
                            })
                            }										
							</select>       
              </div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="form-control-border form-control-transparent form-fs-md d-flex">
							<i class="bi bi-person fs-3 me-2 mt-2"></i>
							<div class="w-100">
								<label class="form-label">Guests &amp; rooms</label>
								<div class="dropdown guest-selector me-2">
									<input type="text" class="form-guest-selector form-control selection-result" value="2 Guests 1 Room" id="dropdownShare" data-bs-auto-close="outside" data-bs-toggle="dropdown"></input>
								
									<ul class="dropdown-menu guest-selector-dropdown" aria-labelledby="dropdownShare">
										<li class="d-flex justify-content-between">
											<div>
												<h6 class="mb-0">Adults</h6>
												<small>Ages 13 or above</small>
											</div>

											<div class="hstack gap-1 align-items-center">
												<button type="button" class="btn btn-link adult-remove p-0 mb-0"><i class="bi bi-dash-circle fs-5 fa-fw"></i></button>
												<h6 class="guest-selector-count mb-0 adults">2</h6>
												<button type="button" class="btn btn-link adult-add p-0 mb-0"><i class="bi bi-plus-circle fs-5 fa-fw"></i></button>
											</div>
										</li>

										<li class="dropdown-divider"></li>

										<li class="d-flex justify-content-between">
											<div>
												<h6 class="mb-0">Child</h6>
												<small>Ages 13 below</small>
											</div>

											<div class="hstack gap-1 align-items-center">
												<button type="button" class="btn btn-link child-remove p-0 mb-0"><i class="bi bi-dash-circle fs-5 fa-fw"></i></button>
												<h6 class="guest-selector-count mb-0 child">0</h6>
												<button type="button" class="btn btn-link child-add p-0 mb-0"><i class="bi bi-plus-circle fs-5 fa-fw"></i></button>
											</div>
										</li>

										<li class="dropdown-divider"></li>
										<li class="d-flex justify-content-between">
											<div>
												<h6 class="mb-0">Rooms</h6>
												<small>Max room 8</small>
											</div>

											<div class="hstack gap-1 align-items-center">
												<button type="button" class="btn btn-link room-remove p-0 mb-0"><i class="bi bi-dash-circle fs-5 fa-fw"></i></button>
												<h6 class="guest-selector-count mb-0 rooms">1</h6>
												<button type="button" class="btn btn-link room-add p-0 mb-0"><i class="bi bi-plus-circle fs-5 fa-fw"></i></button>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-position-md-middle">
					<a class="icon-lg btn btn-round btn-primary mb-0" href="#"><i class="bi bi-search fa-fw"></i></a>
				</div>
			</form>
		</div>
	</div>
</section>

      <section className="pt-0">
        <div className="container">
          <div className="row mb-4 mb-sm-5">
            <div className="col-12 text-center">
              <h4 className="mb-0">1-48 of over 10,000 results for "test"</h4>
            </div>
          </div>

          <div className="row g-4">
            {businessList.map((k) => {
              return (
                <div class="col-md-6 col-xl-4">
				<div class="card shadow p-2 pb-0 h-100">
					<div class="position-absolute top-0 start-0 z-index-1 m-4">
						<div class="badge bg-danger text-white">30% Off</div>
					</div>
          <img
                        src={getImageUrl(k.businessCoverImage)}
                        className="card-img-top"
                        alt="Card image"
                      ></img>
					<div class="card-body px-3 pb-0">
						<div class="d-flex justify-content-between mb-3">
							<a href="#" class="badge bg-dark text-white"><i class="bi fa-fw bi-star-fill me-2 text-warning"></i>4.5</a>
							<a href="#" class="h6 mb-0 z-index-2"><i class="bi fa-fw bi-bookmark"></i></a>
						</div>
						<h5 class="card-title"><a href="hotel-detail.html">{k.registeredName}</a></h5>
            <h5>{k.businessDescription}</h5>
						<ul class="nav nav-divider mb-2 mb-sm-3">
							<li class="nav-item">Air Conditioning</li>
							<li class="nav-item">Wifi</li>
							<li class="nav-item">Kitchen</li>
							<li class="nav-item">Pool</li>
						</ul>
					</div>

					<div class="card-footer pt-0">
						<div class="d-sm-flex justify-content-sm-between align-items-center">
							<div class="d-flex align-items-center">
								<h5 class="fw-normal text-success mb-0 me-1">$750</h5>
								<span class="mb-0 me-2">/day</span>
								<span class="text-decoration-line-through">$1000</span>
							</div>
							<div class="mt-2 mt-sm-0">
              <button  class="btn btn-primary"  onClick={() =>openBookingModal(k.id, k.registeredName)}>
                            Book Now
              </button>
              <Link  className="btn btn-sm btn-primary-soft mb-0 w-100"  to={"/Detail/" + k.id}>
                          View detail<i className="bi bi-arrow-right ms-1"></i>
              </Link>
							</div>
						</div>
					</div>
				</div>
			</div>

              );
            })}            
          </div>
        </div>
      </section>
    </>
  );
}

export default Search;
