
export const LoginValidateColumn = [{ fieldName: 'userId', errorMessage: '', isSuccess: true },
{ fieldName: 'password', errorMessage: '', isSuccess: true },
{ fieldName: 'otp', errorMessage: '', isSuccess: true }]

export const RegisterValidateColumn = [{ fieldName: 'firstName', errorMessage: '', isSuccess: true },
{ fieldName: 'lastName', errorMessage: '', isSuccess: true },
{ fieldName: 'password', errorMessage: '', isSuccess: true },
{ fieldName: 'confirmPassword', errorMessage: '', isSuccess: true }]

export const ProfileValidateColumn = [{ fieldName: 'firstName', errorMessage: '', isSuccess: true },
{ fieldName: 'lastName', errorMessage: '', isSuccess: true }]

export const BusinessProfileValidateColumn = [{fieldName: 'businessTypeId', errorMessage: '', isSuccess: true },
{ fieldName: 'registeredName', errorMessage: '', isSuccess: true },
{ fieldName: 'businessDescription', errorMessage: '', isSuccess: true }]

export const AddressValidateColumn = [{fieldName: 'address', errorMessage: '', isSuccess: true },
{ fieldName: 'state', errorMessage: '', isSuccess: true },
{ fieldName: 'city', errorMessage: '', isSuccess: true },
{ fieldName: 'zip', errorMessage: '', isSuccess: true },
{ fieldName: 'landmark', errorMessage: '', isSuccess: true }]

export const ServiceDetailValidateColumn = [{ fieldName: 'name', errorMessage: '', isSuccess: false },
{ fieldName: 'description', errorMessage: '', isSuccess: false },
{ fieldName: 'rate', errorMessage: '', isSuccess: false }]

export const ProductDetailValidateColumn = [{ fieldName: 'name', errorMessage: '', isSuccess: false },
{ fieldName: 'description', errorMessage: '', isSuccess: false }]

// export const ChangePasswordValidateColumn= [{ fieldName: 'Old Password', errorMessage: '', isSuccess: true },
// { fieldName: 'New Password', errorMessage: '', isSuccess: true },{ fieldName: 'Confirm Password', errorMessage: '', isSuccess: true }]

// export const EmailValidateColumn = [{ fieldName: 'To', errorMessage: '', isSuccess: true },
// { fieldName: 'Message Body', errorMessage: '', isSuccess: true }]

// export const newsValidateColumn = [{ fieldName: 'Title', errorMessage: '', isSuccess: true },
// { fieldName: 'Content', errorMessage: '', isSuccess: true }]

// export const eventValidateColumn= [{ fieldName: 'Name', errorMessage: '', isSuccess: true },
// { fieldName: 'Message', errorMessage: '', isSuccess: true }]

// export const messageValidateColumn= [{ fieldName: 'Name', errorMessage: '', isSuccess: true },
// { fieldName: 'Message', errorMessage: '', isSuccess: true }]

const message=[{ErrorKey:'Required',ErrorMessage:'This field is Required!',Culture:'US'},
{ErrorKey:'PasswordUnmatch',ErrorMessage:'Confirm Password not matched!',Culture:'US'},
 {ErrorKey:'EmailOrMobileRequired',ErrorMessage:'Mobile Number or Email Address Required!',Culture:'US'}];

export const GetValidationMessage=(errorKey)=>{
var result=message.find((key)=>{
    return key.ErrorKey==errorKey;
});
if(result != null)
return result.ErrorMessage;
}