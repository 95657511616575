// import './App.css';
import {React,useState,useEffect,useRef} from 'react';
import { Link,Outlet,useLocation,useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {setUserDetail} from '../Slices/userSlice';
import { ToastContainer } from 'react-toastify';
import {showSaveLoader,showListLoader,getUserKey,checkUserIdentifier,getBusinessTypes,setUserKey,convertDatetime,getImageUrl} from '../common'
import {BusinessProfileModel} from "../Models/Basic-Detail";
import { useDispatch,useSelector } from 'react-redux';
import {CommonModel, PaginationModel} from '../Models/commonModel';
import SignalRService from "../Services/SignalRService";
import { GetAllNotification} from "../Services/Service";
function Main() {
	const location = useLocation(); 
	const navigate = useNavigate();
	const dispatch= useDispatch();
	const [notificationList, setNotification] = useState([]);
	const [pagination, setPagination] = useState(PaginationModel);

	var userDetail =useSelector((store)=>store.userDetail);	

	useEffect(() => {
		SignalRService.addMessageListener((receivedMessage) => {
			setNotification([receivedMessage,...notificationList]);
		});
	
		return () => {
		    SignalRService.stopConnection();
		};
	  }, []);
	
		useEffect(() => {
			checkUserIdentifier(navigate,dispatch);			
		  }, []);	

		const handleLogout=(e)=>{
			setUserKey('');
			dispatch(setUserDetail(null));
			navigate("/");  
		}
		const goToDashboard=()=>{	
			navigate("/Admin/Dashboard");  
		}

		const getAllNotification= async (viewmore)=>{
			var element = document.getElementById('lnk_notification');
		    if(element.classList.contains('show'))
			{
			let startIndex=pagination.startIndex;
			let endIndex=pagination.endIndex;
			if(viewmore == 'viewmore')
			{
				startIndex =  (pagination.endIndex+1);
				endIndex = (pagination.endIndex+10);				
			}
				var response = await GetAllNotification(startIndex,endIndex);
				if (response!=null && response.status == 200 && response.data != null) { 
				{
					setPagination((prevUserData) => ({
						...prevUserData,
						['startIndex']: startIndex,	
						['endIndex']: endIndex	 			
					  }));
					  var notifications=notificationList;
					setNotification(notifications.concat(response.data));
				} }
		}
	}

    return ( 
    <>
    <header id="header-transparent" className="header-transparent">
	<nav className="navbar navbar-dark navbar-expand-xl">
		<div className="container">
			{/* <!-- Logo START --> */}
			<Link className="navbar-brand" to="/">
				<img className="navbar-brand-item" src="logo.svg" alt="logo"></img>
			</Link>
			{/* <!-- Logo END -->

      <!-- Responsive navbar toggler --> */}
			<button className="navbar-toggler ms-auto me-3 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-animation">
					<span></span>
					<span></span>
					<span></span>
				</span>
			</button>

			<div className="navbar-collapse collapse" id="navbarCollapse">
				<ul className="navbar-nav navbar-nav-scroll mx-auto">

					<li className="nav-item"> <Link className="nav-link" to="/">Home</Link> </li>
					<li className="nav-item"> <Link className="nav-link" to="/Contact">Contact</Link> </li>
					<li className="nav-item"> <Link className="nav-link" to="/About">About</Link> </li>	
					<li className="nav-item"> <Link className="nav-link" to="/PrivacyPolicy">Privacy Policy</Link> </li>					
					{ (userDetail!=null && userDetail.firstName=='') &&
					<li className="nav-item"> <Link className="nav-link" to="/Login">Sign In</Link> </li>
                    }
					<li className="nav-item dropdown">
						<a className="nav-link" href="#" id="advanceMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i className="fas fa-ellipsis-h"></i>
						</a>
						<ul className="dropdown-menu min-w-auto" data-bs-popper="none">
							<li> 
								<a className="dropdown-item" href="https://support.webestica.com/" target="_blank">
									<i className="text-warning fa-fw bi bi-life-preserver me-2"></i>Support
								</a> 
							</li>
							<li> 
								<a className="dropdown-item" href="docs/index.html" target="_blank">
									<i className="text-danger fa-fw bi bi-card-text me-2"></i>Documentation
								</a> 
							</li>
							<li> <hr className="dropdown-divider"></hr></li>
							<li> 
								<a className="dropdown-item" href="rtl/index.html" target="_blank">
									<i className="text-info fa-fw bi bi-toggle-off me-2"></i>RTL demo
								</a> 
							</li>
							<li> 
								<a className="dropdown-item" href="https://themes.getbootstrap.com/store/webestica/" target="_blank">
									<i className="text-success fa-fw bi bi-cloud-download-fill me-2"></i>Buy Booking!
								</a> 
							</li>
							<li> <hr className="dropdown-divider"></hr></li>
							<li> 
								<a className="dropdown-item" href="docs/alerts.html" target="_blank">
									<i className="text-orange fa-fw bi bi-puzzle-fill me-2"></i>Components
								</a> 
							</li>
						</ul>
					</li>
				</ul>
			</div>
			{/* <!-- Main navbar END -->

			<!-- Profile and Notification START --> */}
			<ul className="nav flex-row align-items-center list-unstyled ms-xl-auto">
{( userDetail.notificationCount!=0) &&
				<li className="nav-item dropdown ms-0 ms-md-3">					
					<a id='lnk_notification' onClick={getAllNotification} className="nav-notification btn btn-light p-0 mb-0" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
						<i className="bi bi-bell fa-fw"></i>
					</a>		
					<span className="notif-badge" style={{width:"60%",height: '60%',top:'-10px', right: '-5px'}}><span style={{color: 'white',paddingLeft: '20%',fontSize: '14px'}}>{userDetail.notificationCount}</span></span>
					<div className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0">
						<div className="card bg-transparent">					
							<div className="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom">
								{/* <h6 className="m-0">Notifications <span className="badge bg-danger bg-opacity-10 text-danger ms-2">{userDetail.notificationCount}</span></h6> */}
								<a className="small" href="#">Clear all</a>
							</div>
							<div className="card-body p-0">
								<ul className="list-group list-group-flush list-unstyled p-2">	
								{notificationList!=null && notificationList.map((k)=>{																	
								return	<li>
										<a href="#" className="list-group-item list-group-item-action rounded notif-unread border-0 mb-1 p-3">
											<span class="avatar avatar-sm p-0">
											<img class='avatar-img rounded-2' src={getImageUrl(k.profileImage)}></img>
											</span>
											<span style={{position:'absolute',marginLeft: '10px'}}>
											<h6 className="mb-2">{k.notificationFromName}</h6>
											</span>
											<p className="mb-0 small">{k.messageBody}</p>
											<span>{convertDatetime(k.createdDate)}</span>
										</a>
									</li>			
								 })
								}						
									{/* <li>
										<a href="#" className="list-group-item list-group-item-action rounded border-0 mb-1 p-3">
											<h6 className="mb-2">Sunshine saving are here 🌞 save 30% or more on a stay</h6>
											<span>15 Nov 2022</span>
										</a>
									</li> */}
								</ul>
							</div>
							{/* <!-- Card body END -->

							<!-- Card footer --> */}
							<div className="card-footer bg-transparent text-center border-top">
								<span onClick={()=>getAllNotification('viewmore')} className="btn btn-link mb-0 p-0">View more..</span>
							</div>
						</div>
					</div>
					
				</li>
}        
				{/* <!-- Notification dropdown END -->

				<!-- Profile dropdown START --> */}
				{userDetail.profileImage!='' &&
				<li className="nav-item ms-3 dropdown">				
					<a className="avatar avatar-sm p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
						<img className="avatar-img rounded-2" src={getImageUrl(userDetail.profileImage)}  alt="avatar"></img>
					</a>
               
					<ul className="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown">
						<li className="px-3 mb-3">
							<div className="d-flex align-items-center">
								<div className="avatar me-3">
									<img className="avatar-img rounded-circle shadow" src={getImageUrl(userDetail.profileImage)}  alt="avatar"></img>
								</div>
								<div>
									<a className="h6 mt-2 mt-sm-0" href="#">Lori Ferguson</a>
									<p className="small m-0">example@gmail.com</p>
								</div>
							</div>
						</li>

						<li> <hr className="dropdown-divider"></hr></li>
						<li><a className="dropdown-item" href="#"><i className="bi bi-bookmark-check fa-fw me-2"></i>My Bookings</a></li>
						<li><a className="dropdown-item" href="#"><i className="bi bi-heart fa-fw me-2"></i>My Wishlist</a></li>
						<li><a onClick={goToDashboard} className="dropdown-item"><i className="bi bi-gear fa-fw me-2"></i>Settings</a></li>
						<li><a className="dropdown-item" href="#"><i className="bi bi-info-circle fa-fw me-2"></i>Help Center</a></li>
						<li onClick={handleLogout}><a className="dropdown-item" href="#"><i className="bi bi-power fa-fw me-2"></i>Sign Out</a></li>
						<li> <hr className="dropdown-divider"></hr></li>

						<li>
							<div className="modeswitch-wrap" id="darkModeSwitch">
								<div className="modeswitch-item">
									<div className="modeswitch-icon"></div>
								</div>
								<span>Dark mode</span>
							</div>
						</li> 
						
					</ul>					
				</li>
				 }
			</ul>
		
		</div>
	</nav>
	
</header>
<main>
	{ (location.pathname.indexOf('Admin')> -1 && userDetail.firstName!='') &&
<section class="pt-4 pt-md-8 bgsection">
	<div class="container">
		<div class="card rounded-3 border p-3 pb-2">
			<div class="d-sm-flex align-items-center">
				<div class="avatar avatar-xl mb-2 mb-sm-0">
					<img class="avatar-img rounded-circle" src={getImageUrl(userDetail.profileImage)} alt=""></img>
				</div>
				<h4 class="mb-2 mb-sm-0 ms-sm-3"><span class="fw-light">Hi</span> Jacqueline Miller</h4>
				
			</div>
			<button class="btn btn-primary w-100 d-block d-xl-none mt-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#dashboardMenu" aria-controls="dashboardMenu">
				<i class="bi bi-list"></i> Dashboard Menu
			</button>
			<div class="offcanvas-xl offcanvas-end mt-xl-3" tabindex="-1" id="dashboardMenu">
				<div class="offcanvas-header border-bottom p-3">
					<h5 class="offcanvas-title">Menu</h5>
					<button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#dashboardMenu" aria-label="Close"></button>
				</div>
				<div class="offcanvas-body p-3 p-xl-0">
					<div class="navbar navbar-expand-xl">
						<ul class="navbar-nav navbar-offcanvas-menu">
						    <li class="nav-item"><a onClick={goToDashboard} class="nav-link active"><i class="bi bi-house-door fa-fw me-1"></i>Dashboard</a></li>
							<li class="nav-item"><Link to="/Admin/EditProfile" class="nav-link active"><i class="bi bi-bell fa-fw me-1"></i>EditProfile</Link></li>
							<li class="nav-item"><Link to="/Admin/BookingAd" class="nav-link active"><i class="bi bi-bell fa-fw me-1"></i>BookingAd</Link></li>
							<li className="nav-item"> <Link className="nav-link" to="/Admin/VideoStream">Video Chat</Link> </li>	
							<li className="nav-item"> <Link className="nav-link" to="/Admin/IoT">IoT</Link> </li>
							<li className="nav-item"> <Link className="nav-link" to="/Admin/IoTSetting">IoTSetting</Link> </li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
}
<ToastContainer />
<Outlet />
</main>
<footer className="bg-dark py-7">
	<div className="container">
		<div className="row mx-auto">
			<div className="col-md-10 col-xl-6 mx-auto text-center">
				
				<img className="mx-auto h-60px" src="assets/images/logo-light.svg" alt="logo"></img>
				<p className="mt-3 text-white">Speedily say has suitable disposal add boy. On forth doubt miles of child. Exercise joy man children rejoiced.</p>
				{/* <!-- Links --> */}
				<ul className="nav justify-content-between text-primary-hover mt-3 mt-md-0">
					<li className="nav-item"><Link className="nav-link p-2 text-white" to="/About">About</Link></li>
					<li className="nav-item"><a className="nav-link p-2 text-white" href="#">Terms</a></li>
					<li className="nav-item"><a className="nav-link p-2 text-white" href="#">Privacy</a></li>
					<li className="nav-item"><a className="nav-link p-2 text-white" href="#">Career</a></li>
					<li className="nav-item"><Link className="nav-link p-2 text-white" to="/Contact">Contact us</Link></li>
					<li className="nav-item"><a className="nav-link p-2 text-white" href="#">Cookies</a></li>
				</ul>
				{/* <!-- Social media button --> */}
				<ul className="list-inline mt-3 mb-0">
					<li className="list-inline-item"> 
						<a className="btn btn-white btn-sm shadow px-2 text-facebook" href="#">
							<i className="fab fa-fw fa-facebook-f"></i>
						</a> 
					</li>
					<li className="list-inline-item"> 
						<a className="btn btn-white btn-sm shadow px-2 text-instagram" href="#">
							<i className="fab fa-fw fa-instagram"></i>
						</a> 
					</li>
					<li className="list-inline-item"> 
						<a className="btn btn-white btn-sm shadow px-2 text-twitter" href="#">
							<i className="fab fa-fw fa-twitter"></i>
						</a> 
					</li>
					<li className="list-inline-item"> 
						<a className="btn btn-white btn-sm shadow px-2 text-linkedin" href="#">
							<i className="fab fa-fw fa-linkedin-in"></i>
						</a> 
					</li>
				</ul>
				{/* <!-- copyright text --> */}
				<div className="text-muted text-primary-hover mt-3"> Copyrights <a href="#" className="text-muted">©2022 Booking</a>. All rights reserved. </div>
			</div>
		</div>
		
	</div>
</footer>
</>
    );
}

export default Main;