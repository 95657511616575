const GlobalSetting = {
userKey:"userIdentifier",

 BASE_API_URL: 'https://digitalappzone.com/api/',
//  BASE_Reverse_URL: 'https://reverse.digitalappzone.com/',
 // Payment_Api_Key: 'rzp_live_Rlql8mc4lBCRYc',
 // Payment_PPL_Key: 'AaxNhCFmWViJPNWNs0EFlQLv-kVay8-XZsvFIRB2SOvGV5eiwVyzVukU8LTQiyXBhVujPyOQeV7WWOZp',

//  BASE_API_URL: 'https://localhost:50774/',
//  BASE_Reverse_URL: 'https://localhost:7061/',
Payment_Api_Key: 'rzp_test_R3OvKcZ9Z64WLF',
 Payment_PPL_Key: 'ASSCVi2YQD72ZBOelmM1X54oF_qh89RysT1ZFWHWbfu-ESQ_V4Ps0bNY0xaIQfcgmY_i7s9DIfKMYBGt',
 googleClientId:'560698352620-ldst8ihifcpnd5k3mu972e97p9mblgag.apps.googleusercontent.com',
 defaultCoverImage: 'assets/app-assets/images/profile/cover-image/LMSCoverImage.jpg',
 defaultProfileImage: 'assets/app-assets/images/profile/profile-image/default_user.png'
};
 
export default GlobalSetting;

 
//  const GlobalVariable = Object.freeze({
//   BASE_API_URL: 'https://api.lookmyskills.com/',
//  // Payment_Api_Key: 'rzp_live_Rlql8mc4lBCRYc',
//  // Payment_PPL_Key: 'AaxNhCFmWViJPNWNs0EFlQLv-kVay8-XZsvFIRB2SOvGV5eiwVyzVukU8LTQiyXBhVujPyOQeV7WWOZp',
 
// //BASE_API_URL: 'http://localhost:56087/',
// Payment_Api_Key: 'rzp_test_R3OvKcZ9Z64WLF',
//  Payment_PPL_Key: 'ASSCVi2YQD72ZBOelmM1X54oF_qh89RysT1ZFWHWbfu-ESQ_V4Ps0bNY0xaIQfcgmY_i7s9DIfKMYBGt',

//  googleClientId:'560698352620-ldst8ihifcpnd5k3mu972e97p9mblgag.apps.googleusercontent.com',
//  defaultCoverImage: 'assets/app-assets/images/profile/cover-image/LMSCoverImage.jpg',
//  defaultProfileImage: 'assets/app-assets/images/profile/profile-image/default_user.png'
// });

