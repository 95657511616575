import { React, memo, useState, useEffect, useRef } from "react";
//import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { checkValidation, showSaveLoader, showListLoader } from "../common";
import { ServiceDetailModel, ProductModel } from "../Models/AdModel";
import { CommonModel } from "../Models/commonModel";
import {
  ServiceDetailValidateColumn,
  ProductDetailValidateColumn,
} from "../Models/validationFields";
import {
  SaveServiceDetail,
  GetUserServiceDetails,
  RemoveServiceDetail,
  SaveProductDetail,
  GetProductDetails,
  RemoveProductDetail,
} from "../Services/Service";
import Swal from "sweetalert2";

const BookingAd = () => {
  const [serviceDetail, setServiceDetail] = useState(ServiceDetailModel);
  const [serviceDetailList, setServiceDetailList] = useState([]);
  const serviceDetailValidation = useRef(ServiceDetailValidateColumn);
  const [productModel, setProductModel] = useState(ProductModel);
  const [productDetailList, setProducteDetailList] = useState([]);
  const productDetailValidation = useRef(ProductDetailValidateColumn);
  const [showLoader, setShowLoader] = useState(CommonModel);
  const activeTab = useRef("");
  const uploadedImage = useRef();
  const uploadedVideo = useRef();

  useEffect(() => {
    getUserServiceDetails();
  }, []);

  const handleTabClick = (tabName) => {
    if (tabName === "ServiceTab") {
      activeTab.current = tabName;
      getUserServiceDetails();
    } else if (tabName === "ProductDetailTab") {
      activeTab.current = tabName;
      getproductDetails();
    }
  };

  const onFileChange = (event) => {
    const files = event.target.files;
    if (files.length === 0) return;
    const reader = new FileReader();
    uploadedImage.current = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (activeTab.current == "ServiceTab") {
        const imageUrl = reader.result;
        setServiceDetail((prevUserData) => ({
          ...prevUserData,
          ["imagePath"]: imageUrl,
        }));
      } else if (activeTab.current == "ProductDetailTab") {
        var coverImageUrl = reader.result;
      }
    };
  };

  const onVideoFileChange = (event) => {
    const files = event.target.files;
    if (files.length === 0) return;
    const reader = new FileReader();
    uploadedVideo.current = files;

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (activeTab.current == "ServiceTab") {
        const videoUrl = reader.result;
        setServiceDetail((prevUserData) => ({
          ...prevUserData,
          ["videoPath"]: videoUrl,
        }));
      } else if (activeTab.current == "ProductDetailTab") {
        var coverImageUrl = reader.result;
      }
    };
  };

  const getUserServiceDetails = async () => {
    activeTab.current = "";
    showListLoader(setShowLoader, true);
    var response = await GetUserServiceDetails();
    if (response != null && response.status == 200 && response.data != null) {
      showListLoader(setShowLoader, false);
      setServiceDetailList(response.data);
    } else showListLoader(setShowLoader, false);
  };

  const serviceDetailEdit = (e) => {
    const { name, value } = e.target;
    setServiceDetail((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    const validationModel = [];
    checkValidation(e.target.name, e.target.value, validationModel);
    serviceDetailValidation.current.map((k) => {
      var model = validationModel.find((key) => key.fieldName == k.fieldName);
      if (model != undefined && model != null) {
        k.isSuccess = model.isSuccess;
        k.errorMessage = model.errorMessage;
      }
    });
  };

  const serviceDetailSubmit = async (event) => {
    event.preventDefault();
    const validationModel = [];
    ServiceDetailValidateColumn.map((key) => {
      switch (key.fieldName) {
        case "name":
          checkValidation(key.fieldName, serviceDetail.name, validationModel);
          break;
        case "description":
          checkValidation(
            key.fieldName,
            serviceDetail.description,
            validationModel
          );
          break;
        case "rate":
          checkValidation(key.fieldName, serviceDetail.rate, validationModel);
          break;
        default:
          break;
      }
    });

    serviceDetailValidation.current.map((k) => {
      var model = validationModel.find((key) => key.fieldName == k.fieldName);
      if (model != undefined && model != null) {
        k.isSuccess = model.isSuccess;
        k.errorMessage = model.errorMessage;
      }
    });

    let isFormValid = true;
    validationModel.filter((key) => {
      if (key.isSuccess == false) return (isFormValid = false);
    });
    if (isFormValid) {
      showSaveLoader(setShowLoader, true);
      var response = await SaveServiceDetail(
        uploadedImage.current,
        uploadedVideo.current,
        JSON.stringify(serviceDetail)
      );
      if (response != null && response.status == 200 && response.data != null) {
        showSaveLoader(setShowLoader, false);
        uploadedImage.current = null;
        uploadedVideo.current = null;
        setServiceDetail(ServiceDetailModel);
        toast.success("Changes saved successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        showSaveLoader(setShowLoader, false);
        toast.error("Something went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      setServiceDetail((prevUserData) => ({
        ...prevUserData,
        ["isValid"]: isFormValid,
      }));
    }
  };

  const removeServiceDetail = async (serviceId) => {
    Swal.fire({
      title: "Do you want to delete this record?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      //denyButtonText: `Don't save`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        showSaveLoader(setShowLoader, true);
        var response = await RemoveServiceDetail(serviceId);
        if (
          response != null &&
          response.status == 200 &&
          response.data != null
        ) {
          showSaveLoader(setShowLoader, false);
          let serviceList = serviceDetailList.filter((x) => x.id != serviceId);
          setServiceDetailList(serviceList);
          toast.success(response.data.response_text, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          showSaveLoader(setShowLoader, false);
          toast.error("Something went wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
      // else if (result.isDenied) {
      //   Swal.fire('Changes are not saved', '', 'info')
      // }
    });
  };

  const getproductDetails = async () => {
    activeTab.current = "ProductDetailTab";
    showListLoader(setShowLoader, true);
    var response = await GetProductDetails();
    if (response != null && response.status == 200 && response.data != null) {
      showListLoader(setShowLoader, false);
      setProducteDetailList(response.data);
    } else showListLoader(setShowLoader, false);
  };

  const productDetailEdit = (e) => {
    const { name, value } = e.target;
    setProductModel((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    const validationModel = [];
    checkValidation(e.target.name, e.target.value, validationModel);
    productDetailValidation.current.map((k) => {
      var model = validationModel.find((key) => key.fieldName == k.fieldName);
      if (model != undefined && model != null) {
        k.isSuccess = model.isSuccess;
        k.errorMessage = model.errorMessage;
      }
    });
  };

  const productDetailSubmit = async (event) => {
    event.preventDefault();
    const validationModel = [];
    ProductDetailValidateColumn.map((key) => {
      switch (key.fieldName) {
        case "name":
          checkValidation(key.fieldName, productModel.name, validationModel);
          break;
        case "description":
          checkValidation(
            key.fieldName,
            productModel.description,
            validationModel
          );
          break;
        default:
          break;
      }
    });

    productDetailValidation.current.map((k) => {
      var model = validationModel.find((key) => key.fieldName == k.fieldName);
      if (model != undefined && model != null) {
        k.isSuccess = model.isSuccess;
        k.errorMessage = model.errorMessage;
      }
    });

    let isFormValid = true;
    validationModel.filter((key) => {
      if (key.isSuccess == false) return (isFormValid = false);
    });
    if (isFormValid) {
      showSaveLoader(setShowLoader, true);
      var response = await SaveProductDetail(
        uploadedImage.current,
        uploadedVideo.current,
        JSON.stringify(productModel)
      );
      if (response != null && response.status == 200 && response.data != null) {
        showSaveLoader(setShowLoader, false);
        uploadedImage.current = null;
        uploadedVideo.current = null;
        setProductModel(productModel);
        toast.success(response.data.response_text, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        showSaveLoader(setShowLoader, false);
        toast.error("Something went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      setProductModel((prevUserData) => ({
        ...prevUserData,
        ["isValid"]: isFormValid,
      }));
    }
  };

  const removeProductDetail = async (productId) => {
    Swal.fire({
      title: "Do you want to delete this product?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      //denyButtonText: `Don't save`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        showSaveLoader(setShowLoader, true);
        var response = await RemoveProductDetail(productId);
        if (
          response != null &&
          response.status == 200 &&
          response.data != null
        ) {
          showSaveLoader(setShowLoader, false);
          let productList = productDetailList.filter((x) => x.id != productId);
          setProducteDetailList(productList);
          toast.success(response.data.response_text, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          showSaveLoader(setShowLoader, false);
          toast.error("Something went wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
      // else if (result.isDenied) {
      //   Swal.fire('Changes are not saved', '', 'info')
      // }
    });
  };

  return (
    <>
      <div class="container mt-5 vstack gap-4">
        {showLoader.listLoader == true && (
          <div class="loading">
            <div class="spinner">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        )}
        <div class="row" style={{ background: "#c8e3fa" }}>
          <div class="col-12 mb-4 mb-sm-1">
            <div
              class="d-sm-flex justify-content-between align-items-center"
              style={{ marginTop: "1% !important" }}
            >
              <h4 class="h4 mb-2 mb-sm-0">Ad Setting</h4>
              {/* <div class="d-grid"><a href="#" class="btn btn-primary-soft mb-0"><i class="bi bi-plus-lg fa-fw"></i> New Booking</a></div>				 */}
            </div>
          </div>
        </div>
        {/* <h5 className="page-header">
       Booking Ad Setting <small>add/manage your ad setting</small>
      </h5> */}

        <div class="row" style={{ background: "#f7f7f7" }}>
          <div class="col-12 mb-4 mb-sm-5">
            {/* <p class="f-s-12">
              Add quick, dynamic tab functionality to transition through panes
              of local content, even via dropdown menus.
            </p> */}
            <ul class="nav nav-tabs nav-bottom-line nav-responsive nav-justified">
              <li class="nav-item">
                <a
                  href="#serviceRateTab"
                  class="nav-link mb-0 active"
                  data-bs-toggle="tab"
                  onClick={() => handleTabClick("ServiceTab")}
                >
                  <i class="bi bi-briefcase-fill fa-fw me-1"></i> Service Detail
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#productDetailTab"
                  class="nav-link"
                  data-bs-toggle="tab"
                  onClick={() => handleTabClick("ProductDetailTab")}
                >
                  <i class="bi bi-patch-check fa-fw me-1"></i> Staff Detail
                </a>
              </li>
            </ul>
            <div class="tab-content p-2 p-sm-4" id="nav-tabContent">
              <div class="tab-pane fade show active" id="serviceRateTab">
                <form method="POST" onSubmit={serviceDetailSubmit}>
                  <div class="accordion" id="accordionServiceDetail">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Add New Service
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#accordionServiceDetail"
                      >
                        <div class="accordion-body">
                          <div class="card-body">
                            <div class="form-horizontal">
                              <div class="form-group row m-b-10">
                                <label class="col-sm-2 col-form-label">
                                  Service Name{" "}
                                  <span className="text-danger">*</span>
                                  {serviceDetailValidation.current[0]
                                    .isSuccess == false && (
                                    <span class="text-danger">
                                      {
                                        serviceDetailValidation.current[0]
                                          .errorMessage
                                      }
                                    </span>
                                  )}
                                </label>
                                <div class="col-sm-8">
                                  <input
                                    type="text"
                                    className="form-control m-b-5"
                                    name="name"
                                    value={serviceDetail.name}
                                    onChange={serviceDetailEdit}
                                  />
                                </div>
                              </div>
                              <div class="form-group row m-b-5">
                                <label class="col-sm-2 col-form-label">
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                  {serviceDetailValidation.current[1]
                                    .isSuccess == false && (
                                    <span class="text-danger">
                                      {
                                        serviceDetailValidation.current[1]
                                          .errorMessage
                                      }
                                    </span>
                                  )}
                                </label>
                                <div class="col-sm-8">
                                  <textarea
                                    class="form-control"
                                    name="description"
                                    value={serviceDetail.description}
                                    onChange={serviceDetailEdit}
                                    rows="4"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="form-group row m-b-10">
                                <label class="col-sm-2 col-form-label">
                                  Service Rate (INR){" "}
                                  <span className="text-danger">*</span>
                                  {serviceDetailValidation.current[2]
                                    .isSuccess == false && (
                                    <span class="text-danger">
                                      {
                                        serviceDetailValidation.current[2]
                                          .errorMessage
                                      }
                                    </span>
                                  )}
                                </label>
                                <div class="col-sm-8">
                                  <input
                                    type="number"
                                    className="form-control m-b-5"
                                    name="rate"
                                    value={serviceDetail.rate}
                                    onChange={serviceDetailEdit}
                                  />
                                </div>
                              </div>
                              <div class="form-group row m-b-10">
                                <label class="col-sm-2 col-form-label">
                                  Images
                                </label>
                                <div class="col-sm-8">
                                  <input
                                    type="File"
                                    multiple
                                    name="serviceImage"
                                    onChange={onFileChange}
                                  ></input>
                                </div>
                              </div>
                              <div class="form-group row m-b-10">
                                <label class="col-sm-2 col-form-label">
                                  Video
                                </label>
                                <div class="col-sm-8">
                                  <input
                                    type="File"
                                    name="serviceVideo"
                                    onChange={onVideoFileChange}
                                  ></input>
                                </div>
                              </div>
                              <div class="form-group row m-b-0">
                                <div class="offset-sm-2 col-sm-8">
                                  <button
                                    type="submit"
                                    class="btn btn-primary"
                                    disabled={showLoader.saveLoader}
                                  >
                                    {showLoader.saveLoader == false && (
                                      <span> Save Changes</span>
                                    )}
                                    {showLoader.saveLoader == true && (
                                      <span>
                                        {" "}
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>{" "}
                                        &nbsp; Saving...
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="m-0"></hr>
                  <div class="accordion">
                    <h2 class="accordion-header">
                      <button class="accordion-button" type="button">
                        Service List
                      </button>
                    </h2>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div className="row row-space-30">
                        <table className="table m-b-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Service Name</th>
                              <th>Description</th>
                              <th>Rate (INR)</th>
                              <th>Image</th>
                              <th style={{ width: "1%" }}>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {serviceDetailList.map((k, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{k.name}</td>
                                  <td>{k.description}</td>
                                  <td>{k.rate}</td>
                                  <td>
                                    <img
                                      src={k.imagePath}
                                      width="60px"
                                      height="60px"
                                    ></img>
                                  </td>
                                  <td
                                    class="btn-col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <a
                                      href="#"
                                      class="btn btn-primary btn-xs m-r-2"
                                    >
                                      <i class="fa fa-user"></i>
                                    </a>
                                    <a
                                      href="#"
                                      class="btn btn-default btn-xs m-r-2"
                                    >
                                      <i class="fa fa-cog"></i>
                                    </a>
                                    <a
                                      class="btn btn-default btn-xs"
                                      onClick={() => removeServiceDetail(k.id)}
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="tab-pane fade" id="productDetailTab">
                <form method="POST" onSubmit={productDetailSubmit}>
                  <div class="accordion" id="accordionProductDetail">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Add New Staff
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#accordionProductDetail"
                      >
                        <div class="accordion-body">
                          <div class="card">
                            <div class="card-body">
                              <div class="form-horizontal">
                                <div class="form-group row m-b-10">
                                  <label class="col-sm-2 col-form-label">
                                    Staff Name{" "}
                                    <span className="text-danger">*</span>
                                    {productDetailValidation.current[0]
                                      .isSuccess == false && (
                                      <span class="text-danger">
                                        {
                                          productDetailValidation.current[0]
                                            .errorMessage
                                        }
                                      </span>
                                    )}
                                  </label>
                                  <div class="col-sm-8">
                                    <input
                                      type="text"
                                      className="form-control m-b-5"
                                      name="name"
                                      value={productModel.name}
                                      onChange={productDetailEdit}
                                    />
                                  </div>
                                </div>
                                <div class="form-group row m-b-5">
                                  <label class="col-sm-2 col-form-label">
                                    Staff Service{" "}
                                    <span className="text-danger">*</span>
                                    {productDetailValidation.current[1]
                                      .isSuccess == false && (
                                      <span class="text-danger">
                                        {
                                          productDetailValidation.current[1]
                                            .errorMessage
                                        }
                                      </span>
                                    )}
                                  </label>
                                  <div class="col-sm-8">
                                    <textarea
                                      class="form-control"
                                      name="description"
                                      value={productModel.description}
                                      onChange={productDetailEdit}
                                      rows="4"
                                    ></textarea>
                                  </div>
                                </div>
                                <div class="form-group row m-b-10">
                                  <label class="col-sm-2 col-form-label">
                                    Images
                                  </label>
                                  <div class="col-sm-8">
                                    <input
                                      type="File"
                                      multiple
                                      name="productImage"
                                      onChange={onFileChange}
                                    ></input>
                                  </div>
                                </div>
                                <div class="form-group row m-b-10">
                                  <label class="col-sm-2 col-form-label">
                                    Video
                                  </label>
                                  <div class="col-sm-8">
                                    <input
                                      type="File"
                                      name="productVideo"
                                      onChange={onVideoFileChange}
                                    ></input>
                                  </div>
                                </div>
                                <div class="form-group row m-b-0">
                                  <div class="offset-sm-2 col-sm-8">
                                    <button
                                      type="submit"
                                      class="btn btn-primary"
                                      disabled={showLoader.saveLoader}
                                    >
                                      {showLoader.saveLoader == false && (
                                        <span> Save Changes</span>
                                      )}
                                      {showLoader.saveLoader == true && (
                                        <span>
                                          {" "}
                                          <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>{" "}
                                          &nbsp; Saving...
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="m-0"></hr>
                  <div class="accordion">
                    <h2 class="accordion-header">
                      <button class="accordion-button" type="button">
                        Staff List
                      </button>
                    </h2>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div className="row row-space-30">
                        <table className="table m-b-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Staff Name</th>
                              <th>Staff Description</th>
                              <th>Image</th>
                              <th style={{ width: "1%" }}>ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productDetailList.map((k, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{k.name}</td>
                                  <td>{k.description}</td>
                                  <td>
                                    {k.productImages.map((i) => {
                                      if (i.fileType == "Image")
                                        return (
                                          <img
                                            src={i.imageUrl}
                                            width="60px"
                                            height="60px"
                                          ></img>
                                        );
                                      else if (i.fileType == "Video")
                                        return (
                                          <video
                                            src={i.imageUrl}
                                            width="60px"
                                            height="60px"
                                          ></video>
                                        );
                                    })}
                                  </td>
                                  <td
                                    class="btn-col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <a
                                      href="#"
                                      class="btn btn-primary btn-xs m-r-2"
                                    >
                                      <i class="fa fa-user"></i>
                                    </a>
                                    <a
                                      href="#"
                                      class="btn btn-default btn-xs m-r-2"
                                    >
                                      <i class="fa fa-cog"></i>
                                    </a>
                                    <a
                                      class="btn btn-default btn-xs"
                                      onClick={() => removeProductDetail(k.id)}
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(BookingAd);
